import axios from "../../../../api/axios";

export const getAllBatchesApi = async () => {
  try {
    const response = await axios.get("/admin/batches");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getInstructorsApi = async () => {
  try {
    const response = await axios.get("/admin/instructors?instructorRoleId=3");
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getStudentsByBatchApi = async (id) => {
  try {
    const response = await axios.get(`/admin/batches/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getStudentTypesApi = async () => {
  try {
    const res = await axios.get("/admin/student-types");
    return res.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};
export const createBatchApi = async (batchData) => {
  try {
    const response = await axios.post("/admin/batches", batchData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateBatchApi = async (id, batchData) => {
  try {
    const response = await axios.put(`/admin/batches/${id}`, batchData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteBatchApi = async (id) => {
  try {
    const response = await axios.delete(`/admin/batches/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
