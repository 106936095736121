// api/axios.js

import axios from "axios";
import { API_BASE } from ".";

// Helper function to get headers
const getHeaders = () => {
  const token = localStorage.getItem("jwtToken");
  return {
    "x-access-token": token,
  };
};

// Function to update headers with new token
const updateHeaders = (token) => {
  instance.defaults.headers.common["x-access-token"] = token;
};

const instance = axios.create({
  withCredentials: true,
  baseURL: API_BASE,
});

// Set headers initially
instance.defaults.headers.common = getHeaders();
instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      config.headers["x-access-token"] = token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Add error handling and token refresh for the axios instance
instance.interceptors.response.use(
  response => {
    // Check if there's a new token in the response and update headers accordingly
    const newToken = response.headers["x-new-token"];
    if (newToken) {
      localStorage.setItem("jwtToken", newToken);
      updateHeaders(newToken);
    }
    return response;
  },
  error => {
    console.log(error);
    // If the request was unsuccessful, throw the error to the catch() block
    alert(error.response.data.message || error.response.data.error);
  }
);

export default instance;
