import React, { useState } from "react";
import { Button, Popconfirm, message, Space, Table } from "antd";
import { CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { deleteInstructorApi, updateInstructorApi } from "./api";
import EditableCell from "../../../../components/EditableCell";
import AddInstructorModal from "./AddInstructorModal";
const { Column } = Table;

const InstructorTable = ({ instructorList, subjects, instructorRoles }) => {
    const allSubjects = subjects
    const [editingKey, setEditingKey] = useState();
    const deleteConfirm = async (e) => {
        await deleteInstructorApi(e)
        message.success('Instructor Deleted');
        setTimeout(() => {
            window.location.reload()
        }, 250)
    };

    return (
        <>
            <Table dataSource={instructorList} bordered={true}>
                <Column
                    title="Name"
                    dataIndex={"name"}
                    key={"name"}
                    render={(text, record) => {
                        const editable = editingKey == record.id;
                        const recordCopy = { ...record };
                        delete recordCopy.subjects;
                        return (
                            <EditableCell
                                editing={editable}
                                dataIndex="name"
                                title="Name"
                                inputType="text"
                                record={recordCopy}
                                originalValue={text}
                                updateApi={updateInstructorApi}
                                setEditingKey={setEditingKey}
                            >
                                {text}
                            </EditableCell>
                        );
                    }}
                />
                <Column
                    title="Name (in Nepali)"
                    dataIndex={"name_np"}
                    key={"name_np"}
                    render={(text, record) => {
                        const editable = editingKey == record.id;
                        const recordCopy = { ...record };
                        delete recordCopy.subjects;
                        return (
                            <EditableCell
                                editing={editable}
                                dataIndex="name_np"
                                title="Name (in Nepali)"
                                inputType="text"
                                record={recordCopy}
                                originalValue={text}
                                updateApi={updateInstructorApi}
                                setEditingKey={setEditingKey}
                            >
                                {text}
                            </EditableCell>
                        );
                    }}
                />
                <Column
                    title="Email"
                    dataIndex={"email"}
                    key={"email"}
                    render={(text, record) => {
                        const recordCopy = { ...record };
                        delete recordCopy.role;
                        delete recordCopy.subjects;
                        const editable = editingKey == record.id;
                        return (
                            <EditableCell
                                editing={editable}
                                dataIndex="email"
                                title="Email"
                                inputType="text"
                                record={recordCopy}
                                originalValue={text}
                                updateApi={updateInstructorApi}
                                setEditingKey={setEditingKey}
                            >
                                {text}
                            </EditableCell>
                        );
                    }}
                />
                <Column
                    title="Address"
                    dataIndex={"address"}
                    key={"address"}
                    render={(text, record) => {
                        const recordCopy = { ...record };
                        delete recordCopy.role;
                        delete recordCopy.subjects;
                        const editable = editingKey == record.id;
                        return (
                            <EditableCell
                                editing={editable}
                                dataIndex="address"
                                title="Address"
                                inputType="text"
                                record={recordCopy}
                                originalValue={text}
                                updateApi={updateInstructorApi}
                                setEditingKey={setEditingKey}
                            >
                                {text}
                            </EditableCell>
                        );
                    }}
                />
                <Column
                    title="Role"
                    dataIndex={"role"}
                    key={"role"}
                    render={(text, record) => {
                        const recordCopy = { ...record };
                        delete recordCopy.role;
                        delete recordCopy.subjects;
                        const editable = editingKey == record.id;
                        return (
                            <EditableCell
                                editing={editable}
                                dataIndex="instructorRoleId"
                                title="Role"
                                inputType="select"
                                record={recordCopy}
                                originalValue={text}
                                updateApi={updateInstructorApi}
                                setEditingKey={setEditingKey}
                                selectOptions={instructorRoles.map(x => ({ value: x.id, label: x.role }))}
                            >
                                {text}
                            </EditableCell>
                        );
                    }}
                />
                <Column
                    title="Module"
                    dataIndex={"subjects"}
                    key={"subjects"}
                    render={(subjects, record) => {
                        console.log(subjects);
                        const recordCopy = { ...record };
                        delete recordCopy.role;
                        delete recordCopy.subjects;
                        const editable = editingKey == record.id;
                        return (
                            <EditableCell
                                editing={editable}
                                dataIndex="subjects"
                                title="Module"
                                inputType="select_multiple"
                                record={recordCopy}
                                originalValue={subjects.map((subject) => (
                                    subject.subjectId
                                ))}
                                updateApi={updateInstructorApi}
                                setEditingKey={setEditingKey}
                                selectOptions={allSubjects.map(x => ({ value: x.id, label: x.name }))}
                            >
                                {subjects.map((subject) => (
                                    <p>{subject.subjectName}</p>
                                ))}
                            </EditableCell>
                        );
                    }}

                />
                <Column
                    title="Action"
                    key="action"
                    render={(_, record) => (
                        <Space size="middle">
                            {editingKey ?
                                <Button onClick={() => setEditingKey()}>
                                    <CloseOutlined />
                                </Button> :
                                <Button onClick={() => setEditingKey(record.id)}>
                                    <EditOutlined />
                                </Button>
                            }
                            <Popconfirm
                                title="Delete the instructor"
                                description="Are you sure to delete this instructor?"
                                onConfirm={() => deleteConfirm(record.id)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button danger><DeleteOutlined /></Button>
                            </Popconfirm>
                        </Space>
                    )}
                />
            </Table>
            <AddInstructorModal />
        </>
    );
};

export default InstructorTable;
