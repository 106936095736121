import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import FormGenerator from "../../../../components/FormGenerator";
import { addInstructorApi } from "./api";

const AddInstructorModal = ({ addInstructorModalIsOpen, toggleAddInstructorModal, subjects, instructorRoles }) => {
    if (typeof subjects == "undefined") {
        subjects = []
    } if (typeof instructorRoles == "undefined") {
        instructorRoles = [{ id: 3, name: "Instructor" }]
    }

    const handleAddInstructorSubmit = async (data) => {
        try {
            await addInstructorApi(data);
            alert("Instructor Added");
            toggleAddInstructorModal(false);
            window.location.reload();
        } catch (err) {
            alert("Instructor Email Duplicate")
        }
    };
    const fields = [
        { name: "name", label: "Name", type: "text" },
        { name: "name_np", label: "Name (in Nepali)", type: "text", required: false },
        { name: "email", label: "Email", type: "text" },
        { name: "address", label: "Address", type: "text", required: false },
        {
            name: "subject", label: "Module", type: "select",
            select_type: "multiple", options: subjects.map(subject => ({ label: subject.name, value: subject.id })), required: false
        },
        { name: "instructorRoleId", label: "Role", type: "select", options: instructorRoles.map(role => ({ label: role.role, value: role.id })), required: false },
        { name: "password", label: "Password", type: "password" },
        { name: "add_instructor", label: "Add Instructor", type: "button" },
    ];

    return (
        <Modal
            open={addInstructorModalIsOpen}
            onCancel={() => toggleAddInstructorModal(false)}
            footer={false}
            width={"50%"}
        >
            <h2>Add Instructor Form</h2>
            <FormGenerator fields={fields} onSubmit={handleAddInstructorSubmit} />
        </Modal>
    );
};

export default AddInstructorModal;
