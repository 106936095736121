import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Space, Table, message } from "antd";
import { CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  updateExamApi,
  codeAnswerSheetsApi,
  getExamSubjectMarksApi,
  deleteExamApi,
  updateExamSubjectMarksApi,
} from "./api"; // Assuming the API to code answer sheets is named codeAnswerSheetsApi
import EditableCell from "../../../../components/EditableCell";
import ExamSubjectMarksModal from "./ExamSubjectMarksModal";
const { Column } = Table;

const ExamTable = ({ examList }) => {
  const [editingKey, setEditingKey] = useState();
  const [examSubjectId, setExamSubjectId] = useState();
  const [examSubjectMarks, setExamSubjectMarks] = useState([]);
  const [marksModalIsOpen, setMarksModalIsOpen] = useState(false);

  const toggleMarksModal = (status) => {
    setMarksModalIsOpen(status || !marksModalIsOpen);
  };
  const handleAnswerSheetsCoding = async (examSubjectId) => {
    await codeAnswerSheetsApi(examSubjectId);
    window.location.reload(); // Refresh the page
  };

  const handleMarkUpdate = async (markId, markData) => {
    await updateExamSubjectMarksApi(markId, markData);
    getExamSubjectMarks();
  };
  const deleteConfirm = async (e) => {
    await deleteExamApi(e);
    message.success("Exam Deleted");
    setTimeout(() => {
      window.location.reload();
    }, 250);
  };
  const handleShowExamSubjectMarks = async (examSubjectId) => {
    if (examSubjectId) {
      setExamSubjectId(examSubjectId);
      getExamSubjectMarks();
      toggleMarksModal(true);
    }
  };
  const getExamSubjectMarks = async () => {
    const examSubjectMarks = await getExamSubjectMarksApi(examSubjectId);
    setExamSubjectMarks(examSubjectMarks);
  };
  useEffect(() => {
    handleShowExamSubjectMarks(examSubjectId);
  }, [examSubjectId]);
  return (
    <>
      <Table dataSource={examList} bordered={true}>
        <Column
          title="Name"
          dataIndex={"name"}
          key={"name"}
          render={(text, record) => {
            const recordCopy = { ...record };
            delete recordCopy.subjects;
            const editable = editingKey == record.id;
            return (
              <EditableCell
                editing={editable}
                dataIndex="name"
                title="Name"
                inputType="text"
                record={recordCopy}
                originalValue={text}
                updateApi={updateExamApi}
                setEditingKey={setEditingKey}
              >
                {text}
              </EditableCell>
            );
          }}
        />
        <Column
          title="Date of Examination"
          dataIndex={"date_of_examination"}
          key={"date_of_examination"}
          render={(text, record) => {
            const recordCopy = { ...record };
            delete recordCopy.subjects;
            const editable = editingKey == record.id;
            return (
              <EditableCell
                editing={editable}
                dataIndex="date_of_examination"
                title="Date of Examination"
                inputType="date"
                record={recordCopy}
                originalValue={text}
                updateApi={updateExamApi}
                setEditingKey={setEditingKey}
              >
                {text}
              </EditableCell>
            );
          }}
        />
        <Column
          title="Batch Name"
          dataIndex={"batch_name"}
          key={"batch_name"}
          filterMode={'menu'}
          filterSearch={true}
          filters={[...new Map(examList.map(x => [x['batch_name'], { text: x.batch_name, value: x.batch_name }])).values()]}
          onFilter={(value, record) => record.batch_name === value}
        />
        <Column
          title="Remarks"
          dataIndex={"remarks"}
          key={"remarks"}
          render={(text, record) => {
            const recordCopy = { ...record };
            delete recordCopy.subjects;
            const editable = editingKey == record.id;
            return (
              <EditableCell
                editing={editable}
                dataIndex="remarks"
                title="Remarks"
                inputType="text"
                record={recordCopy}
                originalValue={text}
                updateApi={updateExamApi}
                setEditingKey={setEditingKey}
              >
                {text}
              </EditableCell>
            );
          }}
        />
        <Column
          title="Status"
          dataIndex={"status"}
          key={"status"}
          render={(text, record) => {
            const recordCopy = { ...record };
            delete recordCopy.subjects;
            const editable = editingKey == record.id;
            return (
              <EditableCell
                editing={editable}
                dataIndex="status"
                title="Status"
                inputType="select"
                record={recordCopy}
                originalValue={text}
                updateApi={updateExamApi}
                setEditingKey={setEditingKey}
                selectOptions={[
                  "not_started",
                  "exam_completed",
                  "checking",
                  "checking_completed",
                  "results_published",
                  "cancelled",
                  "postponed",
                ].map((x) => ({ value: x, label: x }))}
              >
                {text}
              </EditableCell>
            );
          }}
        />
        <Column title="Is Reexam" dataIndex={"is_reexam"} key={"is_reexam"} />
        <Column
          title="Result Published At"
          dataIndex={"result_published_at"}
          key={"result_published_at"}
          render={(text, record) => {
            const recordCopy = { ...record };
            delete recordCopy.subjects;
            const editable = editingKey == record.id;
            return (
              <EditableCell
                editing={editable}
                dataIndex="result_published_at"
                title="Result Published At"
                inputType="date"
                record={recordCopy}
                originalValue={text}
                updateApi={updateExamApi}
                setEditingKey={setEditingKey}
              >
                {text}
              </EditableCell>
            );
          }}
        />
        <Column
          title="Module"
          dataIndex={"subjects"}
          key={"subjects"}
          render={(text, record) => {
            return (
              <>
                {record.subjects.map((x) => (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {x.subject_name}
                    {[
                      "exam_completed",
                      "checking",
                      "checking_completed",
                      "results_published",
                    ].includes(record.status) &&
                      x.mark_exists != 1 && (
                        <Button
                          onClick={() =>
                            handleAnswerSheetsCoding(x.exam_subject_id)
                          }
                        >
                          Code
                        </Button>
                      )}
                    {[
                      "exam_completed",
                      "checking",
                      "checking_completed",
                      "results_published",
                    ].includes(record.status) &&
                      x.mark_exists == 1 && (
                        <Button
                          onClick={() =>
                            handleShowExamSubjectMarks(x.exam_subject_id)
                          }
                        >
                          Show
                        </Button>
                      )}
                  </div>
                ))}
              </>
            );
          }}
        />

        <Column
          title="Action"
          key="action"
          render={(_, record) => (
            <Space size="middle">
              {editingKey ? (
                <Button onClick={() => setEditingKey()}>
                  <CloseOutlined />
                </Button>
              ) : (
                <Button onClick={() => setEditingKey(record.id)}>
                  <EditOutlined />
                </Button>
              )}

              <Popconfirm
                title="Delete the exam"
                description="Are you sure to delete this exam?"
                onConfirm={() => deleteConfirm(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button danger>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
      <ExamSubjectMarksModal
        examSubjectMarks={examSubjectMarks}
        marksModalIsOpen={marksModalIsOpen}
        toggleMarksModal={toggleMarksModal}
        handleMarkUpdate={handleMarkUpdate}
      />
    </>
  );
};

export default ExamTable;
