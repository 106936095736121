// frontend/src/pages/Admin/pages/BlockedStudents/BlockedStudentsTable.js
import React, { useState } from 'react';
import { Table, Button, Space } from 'antd';
import { createBlockedStudentApi, updateBlockedStudentApi, deleteBlockedStudentApi } from './api';
import EditableCell from '../../../../components/EditableCell';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';

const { Column } = Table;

const BlockedStudentsTable = ({ blockedStudentsList }) => {
  const [editingKey, setEditingKey] = useState('');

  const handleAddBlockedStudent = async () => {
    const pisId = prompt("Enter the pisId of the student to block:");
    const remarks = prompt("Remarks:");
    if (pisId) {
      await createBlockedStudentApi({ pisId,remarks });
      alert("Student added in Blocked List")
      window.location.reload(); // Refresh the page
    }
  };

  const handleDeleteBlockedStudent = async (id) => {
    await deleteBlockedStudentApi(id);
    window.location.reload(); // Refresh the page
  };

  return (
    <>
      <Button onClick={handleAddBlockedStudent}>Add Blocked Student</Button>
      <Table dataSource={blockedStudentsList} bordered={true}>
        <Column
          title="PisId"
          dataIndex={"pisId"}
          key={"pisId"}
          render={(text, record) => {
            const editable = editingKey === record.id;
            return (
              <EditableCell
                editing={editable}
                dataIndex="pisId"
                title="PisId"
                inputType="text"
                record={record}
                originalValue={text}
                updateApi={updateBlockedStudentApi}
                setEditingKey={setEditingKey}
              >
                {text}
              </EditableCell>
            );
          }}
        />
        <Column
          title="Remarks"
          dataIndex={"remarks"}
          key={"remarks"}
          render={(text, record) => {
            const editable = editingKey === record.id;
            return (
              <EditableCell
                editing={editable}
                dataIndex="remarks"
                title="Remarks"
                inputType="text"
                record={record}
                originalValue={text}
                updateApi={updateBlockedStudentApi}
                setEditingKey={setEditingKey}
              >
                {text}
              </EditableCell>
            );
          }}
        />
        <Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
                
                {editingKey ?
                    <Button onClick={() => setEditingKey()}>
                        <CloseOutlined />
                    </Button> : 
                    <Button onClick={() => setEditingKey(record.id)}>
                        <EditOutlined />
                    </Button>
                }
                <Button onClick={() => handleDeleteBlockedStudent(record.id)}>Delete</Button>
            </Space>
          )}
        />
      </Table>
    </>
  );
};

export default BlockedStudentsTable;