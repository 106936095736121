// api/authApi.js

import axios from "../../../api/axios";

export const verifyTokenApi = async (token) => {
  try {
    const res = await axios.get("/student/verify-token");
    return res.data.auth;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const loginApi = async (email, password) => {
  try {
    const res = await axios.post("/student/login", { email, password });
    if (res.data?.auth) {
      localStorage.setItem("jwtToken", res.data.token);
      sessionStorage.setItem("loggedIn", true);
      sessionStorage.setItem("role", "student");
      sessionStorage.setItem("studentId", res.data.student.id);
    }
    return res.data?.auth;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
export const forgotPasswordApi = async (email) => {
  try {
    const res = await axios.post("/student/reset-password", { email });
    return res.data.success;
  } catch (err) {
    console.error(err);
    return false;
  }
};
export const changePasswordApi = async (id, newPassword) => {
  try {
    const res = await axios.put(`/student/${id}/change-password`, { password: newPassword });
    return res.data.success;
  } catch (err) {
    console.error(err);
    return false;
  }
};
export const getStudentDetailsApi = async (id) => {
  try {
    const res = await axios.get(`/student/${id}`);
    return res.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};