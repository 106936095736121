import React, { useState, useEffect } from "react";
import { Button, Modal, Space, Table } from "antd";
import EditableCell from "../../../../components/EditableCell";
import { EditOutlined } from "@ant-design/icons";
import { downloadJSONAsCSV } from "../../utils";

const ExamSubjectMarksModal = ({
  handleMarkUpdate,
  examSubjectMarks,
  marksModalIsOpen,
  toggleMarksModal,
}) => {
  const [editingKey, setEditingKey] = useState();
  useEffect(() => { }, [examSubjectMarks]);

  return (
    <Modal
      open={marksModalIsOpen}
      onCancel={() => toggleMarksModal(false)}
      footer={false}
      width={"70%"}
    >
      <h2>Marks for Exam Subject ID</h2>
      <Button
        type="primary"
        style={{float:"right"}}
        onClick={() => {
          downloadJSONAsCSV(examSubjectMarks,"Exam Subject Marks")
        }}
      >
        Download CSV
      </Button>
      <Table dataSource={examSubjectMarks} bordered={true}>
        <Table.Column
          title="Module"
          dataIndex="subjectName"
          key="subjectName"
        />
        <Table.Column
          title="Module Code"
          dataIndex="subject_code"
          key="subject_code"
        />
        <Table.Column
          title="Answer Sheet Code"
          dataIndex="markSheetCode"
          key="markSheetCode"
        />
        <Table.Column
          title="Student Email"
          dataIndex="studentEmail"
          key="studentId"
        />
        <Table.Column
          title="Student Name"
          dataIndex="studentName"
          key="studentName"
        />
        <Table.Column
          title="Instructor Email"
          dataIndex="instructorEmail"
          key="instructorEmail"
        />
        <Table.Column
          title="Instructor Name"
          dataIndex="instructorName"
          key="instructorName"
        />
        <Table.ColumnGroup title="Exam Mark">
          <Table.Column title="Subjective Mark" dataIndex="subjectiveMark" key="subjectiveMark"
            render={(text, record) => {
              const recordCopy = { id: record.markId };
              const editable = editingKey == record.markId;
              return (
                <EditableCell
                  editing={editable}
                  dataIndex="subjectiveMark"
                  title="Subjective Mark"
                  inputType="number"
                  record={recordCopy}
                  originalValue={text}
                  updateApi={handleMarkUpdate}
                  setEditingKey={setEditingKey}
                  shouldNotReload={true}
                >
                  {text}
                </EditableCell>
              );
            }} />
          <Table.Column title="Objective Mark" dataIndex="objectiveMark" key="objectiveMark"
            render={(text, record) => {
              const recordCopy = { id: record.markId };
              const editable = editingKey == record.markId;
              return (
                <EditableCell
                  editing={editable}
                  dataIndex="objectiveMark"
                  title="Exam Mark"
                  inputType="number"
                  record={recordCopy}
                  originalValue={text}
                  updateApi={handleMarkUpdate}
                  setEditingKey={setEditingKey}
                  shouldNotReload={true}
                >
                  {text}
                </EditableCell>
              );
            }} />
          <Table.Column
            title="Total Mark"
            dataIndex="examMark"
            key="examMark"
          />
        </Table.ColumnGroup>
        <Table.Column
          title="Internal Mark"
          dataIndex="internalMark"
          key="internalMark"
          render={(text, record) => {
            const recordCopy = { id: record.markId };
            const editable = editingKey == record.markId;
            return (
              <EditableCell
                editing={editable}
                dataIndex="internalMark"
                title="Internal Mark"
                inputType="number"
                record={recordCopy}
                originalValue={text}
                updateApi={handleMarkUpdate}
                setEditingKey={setEditingKey}
                shouldNotReload={true}
              >
                {text}
              </EditableCell>
            );
          }}
        />
        <Table.Column
          title="Action"
          key="action"
          render={(_, record) => (
            <Space size="middle">
              <Button onClick={() => setEditingKey(record.markId)}>
                <EditOutlined />
              </Button>
            </Space>
          )}
        />
      </Table>
    </Modal>
  );
};

export default ExamSubjectMarksModal;
