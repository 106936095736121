import axios from "../../../../api/axios";
import { STUDENT_ID } from "../../config";
export const getExamsApi = async () => {
  try {
    const response = await axios.get(`/student/${STUDENT_ID()}/exams`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getExamMarksApi = async (examId) => {
  try {
    const response = await axios.get(`/student/${STUDENT_ID()}/exams/${examId}/marks`);
    return response.data;
  } catch (error) {
    throw error;
  }
}
