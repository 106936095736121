// api/authApi.js

import axios from "../../../api/axios";

export const verifyTokenApi = async (token) => {
  try {
    const res = await axios.get("/admin/verify-token");
    return res.data.auth;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const loginApi = async (email, password) => {
  try {
    const res = await axios.post("/admin/login", { email, password });
    if (res.data?.auth) {
      localStorage.setItem("jwtToken", res.data.token);
      sessionStorage.setItem("role", res.data.admin.role);
      sessionStorage.setItem("adminId", res.data.admin.id);
      sessionStorage.setItem("loggedIn", true);
    }
    return res.data?.auth;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
