import React, { useState, useCallback } from "react";
import { Button, Popconfirm, message, Space, Table } from "antd";
import { CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { deleteAdminApi, updateAdminApi } from "./api";
import EditableCell from "../../../../components/EditableCell";
import AddAdminModal from "./AddAdminModal";
const { Column } = Table;

const AdminTable = ({ adminList }) => {
  const [editingKey, setEditingKey] = useState();
  const [admins, setAdmins] = useState(adminList); // Assuming adminList is the initial state

  const deleteConfirm = async (id) => {
    await deleteAdminApi(id);
    message.success("Admin Deleted");
    // Update the local state instead of reloading the page
    setAdmins((prev) => prev.filter((admin) => admin.id !== id));
  };

  // Use useCallback to memoize the function if it has dependencies
  const toggleEdit = useCallback((id) => {
    setEditingKey((prev) => (prev === id ? null : id));
  }, []);

  return (
    <>
      <Table dataSource={admins} bordered={true}>
        <Column
          title="Name"
          dataIndex={"name"}
          key={"name"}
          render={(text, record) => {
            const editable = editingKey == record.id;
            return (
              <EditableCell
                editing={editable}
                dataIndex="name"
                title="Name"
                inputType="text"
                record={record}
                originalValue={text}
                updateApi={updateAdminApi}
                setEditingKey={setEditingKey}
              >
                {text}
              </EditableCell>
            );
          }}
        />
        <Column
          title="Email"
          dataIndex={"email"}
          key={"email"}
          render={(text, record) => {
            const editable = editingKey == record.id;
            return (
              <EditableCell
                editing={editable}
                dataIndex="email"
                title="Email"
                inputType="text"
                record={record}
                originalValue={text}
                updateApi={updateAdminApi}
                setEditingKey={setEditingKey}
              >
                {text}
              </EditableCell>
            );
          }}
        />
        <Column
          title="Password"
          dataIndex={"password"}
          key={"password"}
          render={(text, record) => {
            const editable = editingKey == record.id;
            return (
              <EditableCell
                editing={editable}
                dataIndex="password"
                title="Password"
                inputType="text"
                record={record}
                originalValue={text}
                updateApi={updateAdminApi}
                setEditingKey={setEditingKey}
              >
                {text}
              </EditableCell>
            );
          }}
        />
        <Column
          title="Action"
          key="action"
          render={(_, record) => (
            <Space size="middle">
              <Button onClick={() => toggleEdit(record.id)}>
                {editingKey === record.id ? (
                  <CloseOutlined />
                ) : (
                  <EditOutlined />
                )}
              </Button>
              <Popconfirm
                title="Delete the admin"
                onConfirm={() => deleteConfirm(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button danger>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
      <AddAdminModal />
    </>
  );
};

export default AdminTable;
