import axios from "../../../../api/axios";

export const getOldRecordsApi = async () => {
  try {
    const res = await axios.get("/admin/old-records");
    return res.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const addOldRecordApi = async (data) => {
  try {
    const res = await axios.post("/admin/old-records", data);
    return res.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const addOldRecordsMultipleApi = async (data) => {
  try {
    const res = await axios.post("/admin/old-records-multiple", data);
    return res.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const updateOldRecordApi = async (id, data) => {
  try {
    const res = await axios.put("/admin/old-records/" + id, data);
    return res.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const deleteOldRecordApi = async (id) => {
  try {
    const res = await axios.delete("/admin/old-records/" + id);
    return res.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};
