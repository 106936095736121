import axios from "../../../../api/axios";

export const getStudentsApi = async () => {
  try {
    const res = await axios.get("/admin/students");
    return res.data;
  } catch (err) {
    console.error(err);
    // alert(err.response.data.error)
    return false
  }
};

export const getStudentMarksApi = async (studentId) => {
  try {
    const res = await axios.get(`/admin/student/${studentId}/marks`);
    return res.data;
  } catch (err) {
    console.error(err);
    // alert(err.response.data.error)
    return false
  }
}

export const updateStudentApi = async (id, data) => {
  try {
    const res = await axios.put("/admin/students/" + id, data);
    return res.data;
  } catch (err) {
    console.error(err);
    // alert(err.response.data.error)
    return false
  }
};

export const deleteStudentApi = async (id) => {
  try {
    const response = await axios.delete(`/admin/students/${id}`);
    return response.data;
  } catch (err) {

    // alert(err.response.data.error)
    return false
  }
};

export const updateStudentBatchApi = async (id, data) => {
  try {
    const res = await axios.put("/admin/student-batch/" + id, data);
    return res.data;
  } catch (err) {
    console.error(err);
    // alert(err.response.data.error)
    return false
  }
};
export const addStudentApi = async (data) => {
  try {
    const res = await axios.post("/admin/students", data);
    return res.data;
  } catch (err) {
    console.error(err);
    // alert(err.response.data.error)
    return false
  }
};

export const getStudentTypesApi = async () => {
  try {
    const res = await axios.get("/admin/student-types");
    return res.data;
  } catch (err) {
    console.error(err);
    // alert(err.response.data.error)
    return false
  }
};

export const getAllBatchesApi = async () => {
  try {
    const res = await axios.get("/admin/batches?completed=0");
    return res.data;
  } catch (err) {
    console.error(err);
    // alert(err.response.data.error)
    return false
  }
};

export const graduateStudentApi = async (id, data) => {
  try {
    const res = await axios.patch(`/admin/student/${id}/graduate`, { graduated_at: data.graduated_at });
    return res.data;
  } catch (err) {
    console.error(err);
    // alert(err.response.data.error)
    return false
  }
}
export const uploadProfilePictureApi = async (id, data) => {
  try {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const res = await axios.put(`/admin/students/${id}/uploadProfilePicture`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return res.data;
  } catch (err) {
    console.error(err);
    // alert(err.response.data.error)
    return false
  }
};