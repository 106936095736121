import axios from "../../../../api/axios";
import { STUDENT_ID } from "../../config";

export const uploadProfilePictureApi = async (id, data) => {
    try {
      const formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }
      const res = await axios.put(`/student/${id}/uploadProfilePicture`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return res.data;
    } catch (err) {
      console.error(err);
      // alert(err.response.data.error)
      return false
    }
  };


  export const uploadCitizenshipPictureApi = async (id, data) => {
    try {
      const formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }
      const res = await axios.put(`/student/${id}/uploadCitizenshipPicture`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return res.data;
    } catch (err) {
      console.error(err);
      // alert(err.response.data.error)
      return false
    }
  };