import React, { useEffect, useState } from 'react';
import { Button, Table } from "antd";
import { PrinterFilled } from "@ant-design/icons";
import { convertToNepaliDate } from "../../../../utils";
import { getStudent, getStudentBatchInfo } from './api';
import { getExamMarksApi } from '../Exam/api';
import StudentMarksModal from './StudentMarksModal';

const { Column } = Table;
const Dashboard = () => {
    const [student, setStudent] = useState(null);
    const [studentList, setStudentList] = useState([])
    const [marks, setMarks] = useState([])

    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleMarksModal = (status) => {
        setIsModalOpen(status || !isModalOpen);
    };
    useEffect(() => {
        const fetchStudent = async () => {
            const data = await getStudent();
            const batchData = await getStudentBatchInfo()
            setStudent(data);
            setStudentList(batchData)
        };
        fetchStudent();
    }, []);

    return (
        <div>
            {student && (
                <div>
                    <h2>{student.name}</h2>
                    <p>Email: {student.email}</p>
                    <p>Address: {student.address}</p>
                    <p>Date of Birth: {new Date(student.date_of_birth).toLocaleDateString()}</p>
                </div>
            )}

            <Table dataSource={studentList} bordered={true}>
                <Column
                    title="Name"
                    dataIndex={"name"}
                    key={"name"}
                    filterMode={'menu'}
                    filterSearch={true}
                    filters={studentList.map(x => ({ text: x.name, value: x.id }))}
                    onFilter={(value, record) => record.id === value}
                />
                <Column
                    title="Login UID"
                    dataIndex={"email"}
                    key={"email"}
                />
                <Column
                    title="PIS (ID)"
                    dataIndex={"pisId"}
                    key={"pisId"}
                />
                <Column
                    title="Batch"
                    dataIndex={"batch_name"}
                    key={"batch_name"}
                    filterMode={'menu'}
                    filterSearch={true}
                    onFilter={(value, record) => record.batchId === value}
                />
                <Column
                    title="Name (in Nepali)"
                    dataIndex={"name_np"}
                    key={"name_np"}
                />
                <Column
                    title="Address"
                    dataIndex={"address"}
                    key={"address"}
                />
                <Column
                    title="Date of Birth"
                    dataIndex={"date_of_birth"}
                    key={"date_of_birth"}
                    defaultSortOrder={"descend"}
                    sorter={(a, b) => {
                        if (!a.date_of_birth || !b.date_of_birth) {
                            return 0;
                        }
                        return a.date_of_birth.localeCompare(b.date_of_birth);
                    }}
                    render={(text, record) => (text && (<>
                        {text} / {text && convertToNepaliDate(text)}
                    </>
                    )
                    )}
                />
                <Column
                    title="Training Type"
                    dataIndex={"type_of_training"}
                    key={"type_of_training"}
                    filters={[
                        { text: 'Long-term', value: 'Long-term' },
                        { text: 'Short-term', value: 'Short-term' },
                        // Add more filters based on the available data set
                    ]}
                    onFilter={(value, record) => record.type_of_training.indexOf(value) === 0}
                />
                <Column
                    title="Training Category"
                    dataIndex={"category"}
                    key={"category"}
                    filters={[
                        { text: 'Senior', value: 'Senior' },
                        { text: 'Junior', value: 'Junior' },
                        { text: 'Junior-fresh', value: 'Junior-fresh' },
                        { text: 'Short-term', value: 'Short-term' },
                        // Add more filters based on the available data set
                    ]}
                    onFilter={(value, record) => record.category.indexOf(value) === 0}
                />
                <Column
                    title="Created At"
                    dataIndex={"created_at"}
                    key={"created_at"}
                    defaultSortOrder={"descend"}
                    sorter={(a, b) => a.created_at.localeCompare(b.created_at)}
                    render={(text, _) => {
                        return (<>
                            {text && (<>
                                {text} / {text && convertToNepaliDate(text)}
                            </>)}
                        </>)
                    }}
                />
                <Column
                    title="Graduated At"
                    dataIndex={"graduated_at"}
                    key={"graduated_at"}
                    defaultSortOrder={"descend"}
                    sorter={(a, b) => {
                        if (!a.graduated_at || !b.graduated_at) {
                            return 0;
                        }
                        return a.graduated_at.localeCompare(b.graduated_at);
                    }}
                    render={(text, record) => (text && (<>
                        {text} / {text && convertToNepaliDate(text)}
                    </>
                    )
                    )}
                />
                <Column
                    title="Action"
                    key="action"
                    render={(_, record) => (
                        <div>

                            <Button onClick={async () => {
                                setStudent(record)
                                try {
                                    if (record.type_of_training != "Short-term") {
                                        const marks = await getExamMarksApi(record.examId)
                                        if (!marks) {
                                            alert("No marks for " + record.name)
                                        } else {
                                            setMarks(marks)
                                            toggleMarksModal(true)
                                        }
                                    } else {
                                        setMarks([])
                                        toggleMarksModal(true)
                                    }
                                }
                                catch (error) {
                                }
                            }} size="small">
                                <PrinterFilled />
                            </Button>
                        </div>
                    )}
                />
            </Table>
            <StudentMarksModal student={student} marks={marks} isModalOpen={isModalOpen} toggleModal={toggleMarksModal} />
        </div>
    );

}

export default Dashboard