import React, { useState } from "react";
import { Button, Space, Popconfirm, message, Table } from "antd";
import { CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { updateOldRecordApi, deleteOldRecordApi } from "./api";
import EditableCell from "../../../../components/EditableCell";
const { Column } = Table;

const OldRecordsTable = ({ oldRecordsList }) => {
    const [editingKey, setEditingKey] = useState();

    const deleteConfirm = async (e) => {
        await deleteOldRecordApi(e)
        message.success('Record Deleted');
        setTimeout(() => {
            window.location.reload()
        }, 250)
    };

    const deleteCancel = (e) => {
        message.error('Click on No');
    };

    return (
        <Table dataSource={oldRecordsList} bordered={true}>
            <Column
                title="Training Name"
                dataIndex={"training_name"}
                key={"training_name"}
                filterMode={'menu'}
                filterSearch={true}
                filters={Array.from(new Set(oldRecordsList.map(x => x.training_name))).map(training_name => ({ text: training_name, value: training_name }))}
                onFilter={(value, record) => record.training_name === value}
                render={(text, record) => {
                    const recordCopy = { ...record };
                    const editable = editingKey == record.id;
                    return (
                        <EditableCell
                            editing={editable}
                            dataIndex="training_name"
                            title="Training Name"
                            inputType="text"
                            record={recordCopy}
                            originalValue={text}
                            updateApi={updateOldRecordApi}
                            setEditingKey={setEditingKey}
                        >
                            {text}
                        </EditableCell>
                    );
                }}
            />
            <Column
                title="Training Date"
                dataIndex={"training_date"}
                key={"training_date"}
                filterMode={'menu'}
                filterSearch={true}
                filters={Array.from(new Set(oldRecordsList.map(x => x.training_date))).map(training_date => ({ text: training_date, value: training_date }))}
                onFilter={(value, record) => record.training_date === value}
                render={(text, record) => {
                    const recordCopy = { ...record };
                    const editable = editingKey == record.id;
                    return (
                        <EditableCell
                            editing={editable}
                            dataIndex="training_date"
                            title="Training Date"
                            inputType="date"
                            record={recordCopy}
                            originalValue={text}
                            updateApi={updateOldRecordApi}
                            setEditingKey={setEditingKey}
                        >
                            {text}
                        </EditableCell>
                    );
                }}
            />
            <Column
                title="Training Group"
                dataIndex={"training_group"}
                key={"training_group"}
                filterMode={'menu'}
                filterSearch={true}
                filters={Array.from(new Set(oldRecordsList.map(x => x.training_group))).map(training_group => ({ text: training_group, value: training_group }))}
                onFilter={(value, record) => record.name === value}
                render={(text, record) => {
                    const recordCopy = { ...record };
                    const editable = editingKey == record.id;
                    return (
                        <EditableCell
                            editing={editable}
                            dataIndex="training_group"
                            title="Training Group"
                            inputType="text"
                            record={recordCopy}
                            originalValue={text}
                            updateApi={updateOldRecordApi}
                            setEditingKey={setEditingKey}
                        >
                            {text}
                        </EditableCell>
                    );
                }}
            />
            <Column
                title="Name"
                dataIndex={"name"}
                key={"name"}
                filterMode={'menu'}
                filterSearch={true}
                filters={Array.from(new Set(oldRecordsList.map(x => x.name))).map(name => ({ text: name, value: name }))}
                onFilter={(value, record) => record.name === value}
                render={(text, record) => {
                    const recordCopy = { ...record };
                    const editable = editingKey == record.id;
                    return (
                        <EditableCell
                            editing={editable}
                            dataIndex="name"
                            title="Name"
                            inputType="text"
                            record={recordCopy}
                            originalValue={text}
                            updateApi={updateOldRecordApi}
                            setEditingKey={setEditingKey}
                        >
                            {text}
                        </EditableCell>
                    );
                }}
            />
            <Column
                title="Certificate Number"
                dataIndex={"certificate_number"}
                key={"certificate_number"}
                filterMode={'menu'}
                filterSearch={true}
                filters={Array.from(new Set(oldRecordsList.map(x => x.certificate_number))).map(certificate_number => ({ text: certificate_number, value: certificate_number }))}
                onFilter={(value, record) => record.certificate_number === value}
                render={(text, record) => {
                    const recordCopy = { ...record };
                    const editable = editingKey == record.id;
                    return (
                        <EditableCell
                            editing={editable}
                            dataIndex="certificate_number"
                            title="Certificate Number"
                            inputType="text"
                            record={recordCopy}
                            originalValue={text}
                            updateApi={updateOldRecordApi}
                            setEditingKey={setEditingKey}
                        >
                            {text}
                        </EditableCell>
                    );
                }}
            />
            <Column
                title="District"
                dataIndex={"district"}
                key={"district"}
                render={(text, record) => {
                    const recordCopy = { ...record };
                    const editable = editingKey == record.id;
                    return (
                        <EditableCell
                            editing={editable}
                            dataIndex="district"
                            title="district"
                            inputType="text"
                            record={recordCopy}
                            originalValue={text}
                            updateApi={updateOldRecordApi}
                            setEditingKey={setEditingKey}
                        >
                            {text}
                        </EditableCell>
                    );
                }}
            />
            <Column
                title="Action"
                key="action"
                render={(_, record) => (
                    <Space wrap>
                        {editingKey ?
                            <Button onClick={() => setEditingKey()} size="small">
                                <CloseOutlined />
                            </Button> :
                            <Button onClick={() => setEditingKey(record.id)} size="small">
                                <EditOutlined />
                            </Button>
                        }
                        <Popconfirm
                            title="Delete the record"
                            description="Are you sure to delete this record?"
                            onConfirm={() => deleteConfirm(record.id)}
                            onCancel={deleteCancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button danger size="small"><DeleteOutlined /></Button>
                        </Popconfirm>
                    </Space>
                )}
            />
        </Table>
    );
};

export default OldRecordsTable;

