import axios from "../../../../api/axios";

export const createBlockedStudentApi = async (data) => {
  try {
    return (await axios.post('/admin/blocked-students', data)).data;
  } catch (error) {
    // Skip any error
  }
};

export const updateBlockedStudentApi = async (id, data) => {
  try {
    return (await axios.put(`/admin/blocked-students/${id}`, data)).data;
  } catch (error) {
    // Skip any error
  }
};

export const deleteBlockedStudentApi = async (id) => {
  try {
    return (await axios.delete(`/admin/blocked-students/${id}`)).data;
  } catch (error) {
    // Skip any error
  }
};

export const getBlockedStudentsApi = async () => {
  try {
    return (await axios.get('/admin/blocked-students')).data;
  } catch (error) {
    // Skip any error
  }
};
