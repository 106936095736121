import axios from "../../../../api/axios";

export const getSubjectsApi = async () => {
  try {
    const response = await axios.get("/admin/subjects");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addSubjectApi = async (subjectData) => {
  try {
    const response = await axios.post("/admin/subjects", subjectData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getInstructorsApi = async () => {
  try {
    const response = await axios.get("/admin/instructors?instructorRoleId=3");
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getAllBatchesApi = async () => {
  try {
    const res = await axios.get("/admin/batches?completed=0");
    return res.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const updateSubjectApi = async (id, subjectData) => {
  try {
    const response = await axios.put(`/admin/subjects/${id}`, subjectData);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const deleteSubjectApi = async (id) => {
  try {
    const response = await axios.delete(`/admin/subjects/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

