import React, { useState, useEffect, useCallback } from "react";

import { getAdminsApi } from "./api";
import AdminTable from "./AdminTable";
import AddAdminModal from "./AddAdminModal";
import { Button, Spin } from "antd";

const Admin = () => {
    const [loading, setLoading] = useState(false);
    const [adminList, setAdminList] = useState([]);
    const [error, setError] = useState(null);

    const [addAdminModalIsOpen, setAddAdminModalIsOpen] = useState(false);
    const toggleAddAdminModal = (status) => {
        setAddAdminModalIsOpen(status || !addAdminModalIsOpen);
    };

    const fetchAdmins = useCallback(async () => {
        try {
            const data = await getAdminsApi();
            setAdminList(data);
        } catch (err) {
            setError("An error occurred. Please try again.");
        }
    }, []);
    useEffect(() => {
        setLoading(true)
        fetchAdmins();
        setLoading(false)
    }, [fetchAdmins]);
    return (
        <>
            <h2>Admin List</h2>
            <Button
                onClick={() => toggleAddAdminModal(true)}
                style={{ float: "right", marginBottom: "10px" }}
            >
                Add Admin
            </Button>
            <AddAdminModal
                addAdminModalIsOpen={addAdminModalIsOpen}
                toggleAddAdminModal={toggleAddAdminModal}
            />
            {!loading ? <AdminTable adminList={adminList} /> : <Spin />}

        </>
    );
};
export default Admin;

