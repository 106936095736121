import React, { useState, useEffect } from "react";
import FormGenerator from "../../../../components/FormGenerator";
import { Modal } from "antd";
import { createExamApi, getExamsApi, associateSubjectsToExamApi, getSubjectApi } from "./api";
import { getAllSubjectsApi } from "../Instructor/api";

const AddExamModal = ({ addExamModalIsOpen, toggleAddExamModal }) => {
  const [exams, setExams] = useState([]);
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    const fetchExams = async () => {
      const exams = await getExamsApi();
      setExams(exams);
    };
    fetchExams();
  }, []);
  useEffect(() => {
    const fetchSubjects = async () => {
      const subjects = await getAllSubjectsApi();
      setSubjects(subjects);
    };
    fetchSubjects();
  }, []);

  const handleAddExamSubmit = async (data) => {

    const subjectData = await getSubjectApi(data.subjectIds[0])
    const examData = {
      name: data.name,
      date_of_examination: data.date_of_examination,
      remarks: data.remarks,
      is_reexam: data.is_reexam,
      batchId: subjectData.batchId
    };
    const examId = await createExamApi(examData);
    const examSubjectData = {
      examId: examId.id,
      subjectIds: data.subjectIds,
    };
    await associateSubjectsToExamApi(examSubjectData);
    alert("Exam Added");
    toggleAddExamModal(false);
    window.location.reload();
  };

  const fields = [
    { name: "name", label: "Name", type: "text" },
    { name: "date_of_examination", label: "Date of Examination", type: "date" },
    { name: "remarks", label: "Remarks", type: "text" },
    {
      name: "subjectIds",
      label: "Module",
      type: "select",
      select_type: "multiple",
      options: subjects.map((subject) => ({ value: subject.id, label: subject.name })),
    },
    { name: "is_reexam", label: "Is Reexam", type: "checkbox", required: false },
    { name: "add_exam", label: "Add Exam", type: "button" },
  ];

  return (
    <Modal
      open={addExamModalIsOpen}
      onCancel={() => toggleAddExamModal(false)}
      footer={false}
      width={"50%"}
    >
      <h2>Add Exam Form</h2>
      <FormGenerator fields={fields} onSubmit={handleAddExamSubmit} />
    </Modal>
  );
};

export default AddExamModal;
