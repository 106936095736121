import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import FormGenerator from "../../../../components/FormGenerator";
import { addAdminApi } from "./api";

const AddAdminModal = ({ addAdminModalIsOpen, toggleAddAdminModal }) => {

    const handleAddAdminSubmit = async (data) => {
        try {
            await addAdminApi(data);
            alert("Admin Added");
            toggleAddAdminModal(false);
            window.location.reload();
        } catch (err) {
            alert("Admin Email Duplicate")
        }
    };
    const fields = [
        { name: "name", label: "Name", type: "text" },
        { name: "email", label: "Email", type: "text" },
        { name: "password", label: "Password", type: "password",required:false },
        { name: "add_admin", label: "Add Admin", type: "button" },
    ];

    return (
        <Modal
            open={addAdminModalIsOpen}
            onCancel={() => toggleAddAdminModal(false)}
            footer={false}
            width={"50%"}
        >
            <h2>Add Admin Form</h2>
            <FormGenerator fields={fields} onSubmit={handleAddAdminSubmit} />
        </Modal>
    );
};

export default AddAdminModal;
