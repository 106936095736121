import React, { useState } from "react";
import { Button, Space, Table } from "antd";
import { DownloadOutlined, EditOutlined, FormOutlined } from "@ant-design/icons";
import ExamSubjectMarksModal from "./ExamSubjectExamMarksModal";
import ExamSubjectInternalMarksModal from "./ExamSubjectInternalMarksModal";
import { getExamSubjectInternalMarksApi, getExamSubjectMarksApi } from "./api";
const { Column } = Table;

const ExamTable = ({ examList }) => {
    const [internalMarksModalIsOpen, setInternalMarksModalIsOpen] = useState(false)
    const [marksModalIsOpen, setMarksModalIsOpen] = useState(false)
    const [examSubjectId, setExamSubjectId] = useState(null)

    const toggleMarksModal = (status) => {
        setMarksModalIsOpen(status || !marksModalIsOpen);
    };
    const handleShowExamSubjectMarks = async (examSubjectId) => {
        setExamSubjectId(examSubjectId)
        toggleMarksModal(true)
    }
    const toggleInternalMarksModal = (status) => {
        setInternalMarksModalIsOpen(status || !internalMarksModalIsOpen);
    };
    const handleShowInternalMarks = async (examSubjectId) => {
        setExamSubjectId(examSubjectId)
        toggleInternalMarksModal(true)
    }

    const handleDownload = async (examSubjectId) => {
        const internalMarks = await getExamSubjectInternalMarksApi(examSubjectId)
        const examMarks = await getExamSubjectMarksApi(examSubjectId)

        const csvData = examMarks.map(mark => ({
            instructorName: mark.instructorName,
            instructorEmail: mark.instructorEmail,
            instructorAddress: mark.instructorAddress,
            subjectName: mark.subjectName,
            subject_code: mark.subject_code,
            markSheetCode: mark.markSheetCode,
            subjectiveMark: mark.subjectiveMark,
            objectiveMark: mark.objectiveMark,
            examMark: mark.examMark,
            attendance: mark.attendance,
            markCreatedAt: mark.markCreatedAt,
        }));
        handleCsvDownload(csvData, `exam_marks-${examMarks[0].subject_code}`)

        const csvInternalData = internalMarks.map(mark => ({
            instructorName: mark.instructorName,
            instructorEmail: mark.instructorEmail,
            instructorAddress: mark.instructorAddress,
            studentName: mark.studentName,
            studentEmail: mark.studentEmail,
            subjectName: mark.subjectName,
            subject_code: mark.subject_code,
            internalMark: mark.internalMark,
            attendance: mark.attendance,
            markCreatedAt: mark.markCreatedAt,
        }));
        handleCsvDownload(csvInternalData, `internal_marks-${examMarks[0].subject_code}`)

    }
    const handleCsvDownload = (csvData, filename) => {

        const csvFields = Object.keys(csvData[0]);
        const csv = csvData.map(row => csvFields.map(fieldName => JSON.stringify(row[fieldName])).join(','));
        csv.unshift(csvFields.map(field => field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())).join(','));
        const csvString = csv.join('\r\n');

        const downloadLink = document.createElement('a');
        downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString);
        downloadLink.target = '_blank';
        downloadLink.download = filename+'.csv';
        downloadLink.click();
    }
    return (
        <>
            <Table dataSource={examList} bordered={true}>
                <Column
                    title="Name"
                    dataIndex={"name"}
                    key={"name"}
                />
                <Column
                    title="Date of Examination"
                    dataIndex={"date_of_examination"}
                    key={"date_of_examination"}
                />
                <Column
                    title="Remarks"
                    dataIndex={"remarks"}
                    key={"remarks"}
                />
                <Column
                    title="Status"
                    dataIndex={"status"}
                    key={"status"}
                />
                <Column
                    title="Module"
                    dataIndex={"subject_name"}
                    key={"subject_name"}
                />
                <Column
                    title="Result Published At"
                    dataIndex={"result_published_at"}
                    key={"result_published_at"}
                />
                <Column
                    title="Action"
                    key="action"
                    render={(_, record) => (
                        <Space size="middle">
                            {['checking'].includes(record.status) && record.mark_exists == 1 &&
                                <Button onClick={() => handleShowExamSubjectMarks(record.exam_subject_id)} title="Exam Marks">
                                    <EditOutlined />
                                </Button>}
                            {!(["checking_completed", 'results_published', 'cancelled'].includes(record.status)) && record.mark_exists == 1 &&
                                <Button onClick={() => handleShowInternalMarks(record.exam_subject_id)} title="Internal Marks">
                                    <FormOutlined />
                                </Button>}

                            <Button onClick={() => handleDownload(record.exam_subject_id)} title="Download Marks">
                                <DownloadOutlined />
                            </Button>
                        </Space>
                    )}
                />
            </Table >
            <ExamSubjectMarksModal examSubjectId={examSubjectId} marksModalIsOpen={marksModalIsOpen} toggleMarksModal={toggleMarksModal} />
            <ExamSubjectInternalMarksModal examSubjectId={examSubjectId} internalMarksModalIsOpen={internalMarksModalIsOpen} toggleInternalMarksModal={toggleInternalMarksModal} />
        </>
    );
};

export default ExamTable;

