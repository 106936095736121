import axios from "../../../../api/axios";
export const getAverageMarksPerSubjectInExamApi = async () => {
  try {
    const res = await axios.get("/admin/analytics/exams/subjects/average");
    return res.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getStudentCountByProvinceApi = async () => {
  return (await axios.get("/admin/analytics/student/province/total")).data
}

export const getStudentCountByGenderApi = async () => {
  return (await axios.get("/admin/analytics/student/gender/total")).data
}
export const getBatchesStatusApi = async () => {
  return (await axios.get("/admin/analytics/batches/status")).data
}