import React, { useState } from "react";
import { Button, Space, Popconfirm, message, Table } from "antd";
import {
  IdcardOutlined,
  CloseOutlined,
  UserOutlined,
  DeleteOutlined,
  EditOutlined,
  PrinterFilled,
} from "@ant-design/icons";
import {
  updateStudentApi,
  graduateStudentApi,
  getStudentMarksApi,
  updateStudentBatchApi,
  deleteStudentApi,
} from "./api";
import EditableCell from "../../../../components/EditableCell";
import StudentMarksModal from "./StudentMarksModal";
import {
  convertToNepaliDate,
  generateShortTermCertificate,
  generateLongTermCertificate,
  generateLongTermTranscript,
  provinces,
  designationList,
  getDivision,
} from "../../../../utils";
import { API_BASE } from "../../../../api";
const { Column } = Table;

const API_HOST = process.env.API_HOST;

const StudentTable = ({
  studentList,
  batches,
  hideColumns = [],
  showMarks = false,
}) => {
  const [editingKey, setEditingKey] = useState();
  const [student, setStudent] = useState(null);
  const [marks, setMarks] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const genders = ["Male", "Female", "Other"];
  const toggleMarksModal = (status) => {
    setIsModalOpen(status || !isModalOpen);
  };
  const deleteConfirm = async (e) => {
    await deleteStudentApi(e);
    message.success("Student Deleted");
    setTimeout(() => {
      window.location.reload();
    }, 250);
  };

  const deleteCancel = (e) => {
    message.error("Click on No");
  };
  const studentBatches = studentList.map((x) => ({
    batch_name: x["batch_name"],
    id: x["batchId"],
  }));
  const uniqueStudentBatches = Array.from(
    new Set(studentBatches.map((batch) => batch.id))
  ).map((id) => studentBatches.find((batch) => batch.id === id));

  return (
    <>
      <Table dataSource={studentList} bordered={true}>
        {!hideColumns.includes("name") && (
          <Column
            title="Name"
            dataIndex={"name"}
            key={"name"}
            filterMode={"menu"}
            filterSearch={true}
            filters={studentList.map((x) => ({ text: x.name, value: x.id }))}
            onFilter={(value, record) => record.id === value}
            render={(text, record) => {
              const recordCopy = { ...record };
              const editable = editingKey == record.id;
              return (
                <EditableCell
                  editing={editable}
                  dataIndex="name"
                  title="Name"
                  inputType="text"
                  record={recordCopy}
                  originalValue={text}
                  updateApi={updateStudentApi}
                  setEditingKey={setEditingKey}
                >
                  {text}
                </EditableCell>
              );
            }}
          />
        )}
        {!hideColumns.includes("email") && (
          <Column
            title="Login UID"
            dataIndex={"email"}
            key={"email"}
            render={(text, record) => {
              const recordCopy = { ...record };
              const editable = editingKey == record.id;
              return (
                <EditableCell
                  editing={editable}
                  dataIndex="email"
                  title="Email"
                  inputType="text"
                  record={recordCopy}
                  originalValue={text}
                  updateApi={updateStudentApi}
                  setEditingKey={setEditingKey}
                >
                  {text}
                </EditableCell>
              );
            }}
          />
        )}
        <Column
          title="PIS (ID)"
          dataIndex={"pisId"}
          key={"pisId"}
          render={(text, record) => {
            const recordCopy = { ...record };
            const editable = editingKey == record.id;
            return (
              <EditableCell
                editing={editable}
                dataIndex="pisId"
                title="PIS (ID)"
                inputType="text"
                record={recordCopy}
                originalValue={text}
                updateApi={updateStudentApi}
                setEditingKey={setEditingKey}
              >
                {text}
              </EditableCell>
            );
          }}
        />
        {!hideColumns.includes("batchId") && (
          <Column
            title="Batch"
            dataIndex={"batchId"}
            key={"batchId"}
            filterMode={"menu"}
            filterSearch={true}
            filters={uniqueStudentBatches.map((x) => ({
              text: x.batch_name,
              value: x.id,
            }))}
            onFilter={(value, record) => record.batchId === value}
            render={(text, record) => {
              const recordCopy = { ...record, id: record.studentBatchId };
              const editable = editingKey == record.id;
              return (
                <EditableCell
                  editing={editable}
                  dataIndex="batchId"
                  title="Batch"
                  inputType="select"
                  record={recordCopy}
                  originalValue={record.batch_name}
                  updateApi={updateStudentBatchApi}
                  setEditingKey={setEditingKey}
                  selectOptions={batches.map((x) => ({
                    value: x.id,
                    label: x.batch_name,
                  }))}
                >
                  {record.batch_name}
                </EditableCell>
              );
            }}
          />
        )}
        {!hideColumns.includes("name_np") && (
          <Column
            title="Name (in Nepali)"
            dataIndex={"name_np"}
            key={"name_np"}
            render={(text, record) => {
              const recordCopy = { ...record };
              const editable = editingKey == record.id;
              return (
                <EditableCell
                  editing={editable}
                  dataIndex="name_np"
                  title="Name (in Nepali)"
                  inputType="text"
                  record={recordCopy}
                  originalValue={text}
                  updateApi={updateStudentApi}
                  setEditingKey={setEditingKey}
                >
                  {text}
                </EditableCell>
              );
            }}
          />
        )}
        <Column
          title="Address"
          dataIndex={"address"}
          key={"address"}
          render={(text, record) => {
            const recordCopy = { ...record };
            const editable = editingKey == record.id;
            return (
              <EditableCell
                editing={editable}
                dataIndex="address"
                title="Address"
                inputType="text"
                record={recordCopy}
                originalValue={text}
                updateApi={updateStudentApi}
                setEditingKey={setEditingKey}
              >
                {text}
              </EditableCell>
            );
          }}
        />
        {/* <Column
          title="Date of Birth"
          dataIndex={"date_of_birth"}
          key={"date_of_birth"}
          defaultSortOrder={"descend"}
          sorter={(a, b) => {
            if (!a.date_of_birth || !b.date_of_birth) {
              return 0;
            }
            return a.date_of_birth.localeCompare(b.date_of_birth);
          }}
          render={(text, record) => {
            const recordCopy = { ...record };
            const editable = editingKey == record.id;
            return (
              <EditableCell
                editing={editable}
                dataIndex="date_of_birth"
                title="Date of Birth"
                inputType="date"
                record={recordCopy}
                originalValue={text}
                updateApi={updateStudentApi}
                setEditingKey={setEditingKey}
              >
                {text && (<>
                  {text} / {text && convertToNepaliDate(text)}
                </>
                )}
              </EditableCell>
            );
          }}
        /> */}
        {/* <Column
          title="Training Type"
          dataIndex={"type_of_training"}
          key={"type_of_training"}
          filters={[
            { text: 'Long-term', value: 'Long-term' },
            { text: 'Short-term', value: 'Short-term' },
            // Add more filters based on the available data set
          ]}
          onFilter={(value, record) => record.type_of_training.indexOf(value) === 0}
        /> */}
        {!hideColumns.includes("category") && (
          <Column
            title="Training Category"
            dataIndex={"category"}
            key={"category"}
            filters={[
              { text: "Senior", value: "Senior" },
              { text: "Junior", value: "Junior" },
              { text: "Junior-fresh", value: "Junior-fresh" },
              { text: "Short-term", value: "Short-term" },
              // Add more filters based on the available data set
            ]}
            onFilter={(value, record) => record.category.indexOf(value) === 0}
          />
        )}
        {!hideColumns.includes("province") && (
          <Column
            title="Province"
            dataIndex={"province"}
            key={"province"}
            filterMode={"menu"}
            filterSearch={true}
            filters={provinces.map((x) => ({ text: x, value: x }))}
            onFilter={(value, record) => record.province === value}
            render={(text, record) => {
              const recordCopy = { ...record };
              const editable = editingKey == record.id;
              return (
                <EditableCell
                  editing={editable}
                  dataIndex="province"
                  title="Province"
                  inputType="select"
                  record={recordCopy}
                  originalValue={text}
                  updateApi={updateStudentApi}
                  setEditingKey={setEditingKey}
                  selectOptions={provinces.map((x) => ({ text: x, value: x }))}
                >
                  {record.province}
                </EditableCell>
              );
            }}
          />
        )}

        {!hideColumns.includes("gender") && (
          <Column
            title="Gender"
            dataIndex={"gender"}
            key={"gender"}
            filterMode={"menu"}
            filterSearch={true}
            filters={genders.map((x) => ({ text: x, value: x }))}
            onFilter={(value, record) => record.gender === value}
            render={(text, record) => {
              const recordCopy = { ...record };
              const editable = editingKey == record.id;
              return (
                <EditableCell
                  editing={editable}
                  dataIndex="gender"
                  title="Gender"
                  inputType="select"
                  record={recordCopy}
                  originalValue={text}
                  updateApi={updateStudentApi}
                  setEditingKey={setEditingKey}
                  selectOptions={genders.map((x) => ({ text: x, value: x }))}
                >
                  {record.gender}
                </EditableCell>
              );
            }}
          />
        )}
        {!hideColumns.includes('citizenship_number') &&
          <Column
            title="Citizenship Number"
            dataIndex={"citizenship_number"}
            key={"citizenship_number"}
            render={(text, record) => {
              const recordCopy = { ...record };
              const editable = editingKey == record.id;
              return (
                <EditableCell
                  editing={editable}
                  dataIndex="citizenship_number"
                  title="Citizenship Number"
                  inputType="text"
                  record={recordCopy}
                  originalValue={text}
                  updateApi={updateStudentApi}
                  setEditingKey={setEditingKey}
                >
                  {text}
                </EditableCell>
              );
            }}
          />}
        {!hideColumns.includes('district') &&

          <Column
            title="Issue District"
            dataIndex={"district"}
            key={"district"}
            render={(text, record) => {
              const recordCopy = { ...record };
              const editable = editingKey == record.id;
              return (
                <EditableCell
                  editing={editable}
                  dataIndex="district"
                  title="Issue District"
                  inputType="text"
                  record={recordCopy}
                  originalValue={text}
                  updateApi={updateStudentApi}
                  setEditingKey={setEditingKey}
                >
                  {text}
                </EditableCell>
              );
            }}
          />
        }
        {/* {!hideColumns.includes('date_of_issue') &&
          <Column
            title="Issue Date"
            dataIndex={"date_of_issue"}
            key={"date_of_issue"}
            render={(text, record) => {
              const recordCopy = { ...record };
              const editable = editingKey == record.id;
              return (
                <EditableCell
                  editing={editable}
                  dataIndex="date_of_issue"
                  title="Issue Date"
                  inputType="text"
                  record={recordCopy}
                  originalValue={text}
                  updateApi={updateStudentApi}
                  setEditingKey={setEditingKey}
                >
                  {text}
                </EditableCell>
              );
            }}
          />
        } */}
        {!hideColumns.includes("designation") && (
          <Column
            title="Designation"
            dataIndex={"designation"}
            key={"designation"}
            filterMode={"menu"}
            filterSearch={true}
            filters={designationList.map((x) => ({ text: x, value: x }))}
            onFilter={(value, record) => record.province === value}
            render={(text, record) => {
              const recordCopy = { ...record };
              const editable = editingKey == record.id;
              return (
                <EditableCell
                  editing={editable}
                  dataIndex="designation"
                  title="Designation"
                  inputType="select_datalist"
                  record={recordCopy}
                  originalValue={text}
                  updateApi={updateStudentApi}
                  setEditingKey={setEditingKey}
                  selectOptions={designationList.map((x) => ({
                    text: x,
                    value: x,
                  }))}
                >
                  {record.designation}
                </EditableCell>
              );
            }}
          />
        )}
        {/* <Column
          title="Created At"
          dataIndex={"created_at"}
          key={"created_at"}
          defaultSortOrder={"descend"}
          sorter={(a, b) => a.created_at.localeCompare(b.created_at)}
          render={(text, _) => {
            return (<>
              {text && (<>
                {text} / {text && convertToNepaliDate(text)}
              </>)}
            </>)
          }}
        /> */}
        {!hideColumns.includes("graduated_at") && (
          <Column
            title="Graduated At"
            dataIndex={"graduated_at"}
            key={"graduated_at"}
            // sorter={(a, b) => a.graduated_at.localeCompare(b?.graduated_at)}
            render={(text, record) => {
              console.log(text);
              const recordCopy = { ...record, id: record.studentBatchId };
              // const recordCopy = { id: record.studentBatchId };
              const editable = editingKey == record.id;
              return (
                <EditableCell
                  editing={editable}
                  dataIndex="graduated_at"
                  title="Graduated At"
                  inputType="date"
                  record={recordCopy}
                  originalValue={text}
                  updateApi={graduateStudentApi}
                  setEditingKey={setEditingKey}
                >
                  {text && (
                    <>
                      {text} / {text && convertToNepaliDate(text)}
                    </>
                  )}
                  {text == null && <>Not Graduated</>}
                </EditableCell>
              );
            }}
          />
        )}

        {showMarks && (
          <Column title="Exam Marks" dataIndex={"examMark"} key={"examMark"} />
        )}
        {showMarks && (
          <Column
            title="Internal Marks"
            dataIndex={"internalMark"}
            key={"internalMark"}
          />
        )}

        <Column
          title="Action"
          key="action"
          render={(_, record) => (
            <Space wrap>
              {editingKey ? (
                <Button onClick={() => setEditingKey()} size="small">
                  <CloseOutlined />
                </Button>
              ) : (
                <Button onClick={() => setEditingKey(record.id)} size="small">
                  <EditOutlined />
                </Button>
              )}
              {record.photo_url && (
                <Button
                  onClick={() =>
                    window.open(`${API_BASE}/${record.photo_url}`, "_blank")
                  }
                  size="small"
                >
                  <UserOutlined />
                </Button>
              )}
              {record.citizenship_photo_url && (
                <Button
                  onClick={() =>
                    window.open(
                      `${API_BASE}/${record.citizenship_photo_url}`,
                      "_blank"
                    )
                  }
                  size="small"
                >
                  <IdcardOutlined />
                </Button>
              )}
              <Button
                onClick={async () => {
                  setStudent(record);
                  console.log(record);
                  try {
                    if (record.type_of_training != "Short-term") {
                      const marks = await getStudentMarksApi(record.id);

                      if (!marks) {
                        alert("No marks for " + record.name);
                      } else {
                        const { req_division, percentage, grand_total } =
                          getDivision(marks);
                        record.division_np = req_division["np"];
                        record.division_en = req_division["en"];
                        record.grand_total = grand_total;
                        record.percentage = percentage;
                        // setMarks(marks)
                        // toggleMarksModal(true)
                        await generateLongTermCertificate(record);
                        await generateLongTermTranscript(record, marks);
                      }
                    } else {
                      await generateShortTermCertificate(record);
                      // toggleMarksModal(true)
                    }
                  } catch (error) {}
                }}
                size="small"
              >
                <PrinterFilled />
              </Button>
              <Popconfirm
                title="Delete the student"
                description="Are you sure to delete this student?"
                onConfirm={() => deleteConfirm(record.id)}
                onCancel={deleteCancel}
                okText="Yes"
                cancelText="No"
              >
                <Button danger size="small">
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
      <StudentMarksModal
        student={student}
        marks={marks}
        isModalOpen={isModalOpen}
        toggleModal={toggleMarksModal}
      />
    </>
  );
};

export default StudentTable;
