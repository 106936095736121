import { Button } from "antd";
import { Layout } from "antd";
import { checkAuth } from "../utils";
import LMTCLogo from "../static/lmtc_logo.png";

const { Header } = Layout;

function HomePage() {
    const handleLogout = () => {
        sessionStorage.removeItem("loggedIn");
        localStorage.removeItem("jwtToken");
        window.location = "/";
    };

    return (
        <Layout>
            <Header className="App-header">
                <div style={{ display: 'flex'}}>
                    <div style={{width:"100%", textAlign:"left"}}>
                        <img
                            src={LMTCLogo}
                            alt="logo"
                            style={{ height: "60%", background: "white" }}
                        />
                    </div>
                    <div style={{width:"100%", textAlign:"right"}}>
                        <Button type="primary" style={{ margin: "10px" }} onClick={() => { window.location = "/admin" }}>Admin</Button>
                        <Button type="primary" style={{ margin: "10px" }} onClick={() => { window.location = "/instructor" }}>Instructor</Button>
                        <Button type="primary" style={{ margin: "10px" }} onClick={() => { window.location = "/student" }}>Student</Button>
                    </div>
                </div>
            </Header>
            <Layout>
                <div style={{position:"relative"}}>
                    <img src="/lmtc1.jpeg" style={{width:"100%"}}/>
                    <h1 style={{position:"absolute", top:0, left:"40%"}}>Exam Management System</h1>
                </div>
            </Layout>
        </Layout>
    );
}

export default HomePage;
