import React, { useState, useEffect } from "react";
import { Modal, Table, Button, Space } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { INSTRUCTOR_ID } from "../../config";
import { getExamSubjectMarksApi, updateExamSubjectMarksApi } from "./api";
import EditableCell from "../../../../components/EditableCell";

const ExamSubjectMarksModal = ({ examSubjectId, marksModalIsOpen, toggleMarksModal }) => {

    const [examSubjectMarks, setExamSubjectMarks] = useState([]);
    const [editingKey, setEditingKey] = useState();
    const getMarks = async () => {
        const examSubjectMarks = await getExamSubjectMarksApi(examSubjectId);
        const randomizedExamSubjectMarks = examSubjectMarks.sort(() => Math.random() - 0.5);
        setExamSubjectMarks(randomizedExamSubjectMarks)

    }
    useEffect(() => {
        if (examSubjectId && marksModalIsOpen)
            getMarks()
    }, [examSubjectId, marksModalIsOpen]);

    const handleMarkUpdate = async (markId, markData) => {
        await updateExamSubjectMarksApi(markId, markData)
        await getMarks()
    }
    return (
        <Modal
            open={marksModalIsOpen}
            onCancel={() => toggleMarksModal(false)}
            footer={false}
            width={"70%"}
        >
            <h2>Marks for Exam Subject ID</h2>
            <Table dataSource={examSubjectMarks} bordered={true}>
                <Table.Column title="Module" dataIndex="subjectName" key="subjectName" />
                <Table.Column title="Module Code" dataIndex="subject_code" key="subject_code" />
                <Table.Column title="Answer Sheet Code" dataIndex="markSheetCode" key="markSheetCode" />
                <Table.ColumnGroup title="Exam Mark">
                    <Table.Column title="Subjective Mark" dataIndex="subjectiveMark" key="subjectiveMark"
                        render={(text, record) => {
                            const recordCopy = { id: record.markId };
                            const editable = editingKey == record.markId;
                            return (
                                <EditableCell
                                    editing={editable}
                                    dataIndex="subjectiveMark"
                                    title="Subjective Mark"
                                    inputType="number"
                                    record={recordCopy}
                                    originalValue={text}
                                    updateApi={handleMarkUpdate}
                                    setEditingKey={setEditingKey}
                                    shouldNotReload={true}
                                >
                                    {text}
                                </EditableCell>
                            );
                        }} />
                    <Table.Column title="Objective Mark" dataIndex="objectiveMark" key="objectiveMark"
                        render={(text, record) => {
                            const recordCopy = { id: record.markId };
                            const editable = editingKey == record.markId;
                            return (
                                <EditableCell
                                    editing={editable}
                                    dataIndex="objectiveMark"
                                    title="Exam Mark"
                                    inputType="number"
                                    record={recordCopy}
                                    originalValue={text}
                                    updateApi={handleMarkUpdate}
                                    setEditingKey={setEditingKey}
                                    shouldNotReload={true}
                                >
                                    {text}
                                </EditableCell>
                            );
                        }} />
                    <Table.Column
                        title="Total Mark"
                        dataIndex="examMark"
                        key="examMark"
                    />
                </Table.ColumnGroup>
                <Table.Column
                    title="Action"
                    key="action"
                    render={(_, record) => (
                        <Space size="middle">
                            {(record.instructorId == INSTRUCTOR_ID() || record.instructorId == null) && <Button onClick={() => setEditingKey(record.markId)}>
                                <EditOutlined />
                            </Button>}
                        </Space>
                    )}
                />
            </Table>
        </Modal>
    );
};

export default ExamSubjectMarksModal;
