import React, { useState } from "react";
import { forgotPasswordApi } from "../../api/authApi";
import "./ForgotPassword.css";
import { Button, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import FormGenerator from "../../../../components/FormGenerator";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const handleSubmit = async ({ email }) => {
    try {
      const success = await forgotPasswordApi(email);
      if (success) {
        navigate("/login"); // If successful, redirect to login page
      } else {
        setError("An error occurred. Please try again");
      }
    } catch (err) {
      setError("An error occurred. Please try again");
    }
  };

  const fields = [
    { name: "email", label: "Email", type: "email" },
    { name: "submit_btn", label: "Submit", type: "button" },
  ];
  return (
    <div className="ForgotPassword">
      <h2>Forgot Password</h2>
      <FormGenerator fields={fields} onSubmit={handleSubmit} />
      {error && <p>{error}</p>}
    </div>
  );
};

export default ForgotPassword;