import AppRoutes from "./routes";
import "./App.css";

import React, { useEffect, useState } from "react";
import { MailOutlined } from "@ant-design/icons";
import { Button, Menu } from "antd";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";
import { Layout } from "antd"
import { checkAuth } from "../../utils";
import LMTCLogo from "../../static/lmtc_logo.png"

const { Footer, Header, Sider } = Layout;
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const items = [
  getItem("Dashboard", "dashboard", <MailOutlined />, [
    getItem("Exams", "exams"),
    getItem("Change Password", "change-password"),
  ])
];

function InstructorApp() {
  const navigate = useNavigate();
  const location = useLocation();
  const onClick = (e) => {
    navigate(e.key);
  };
  const handleLogout = () => {
    sessionStorage.removeItem("loggedIn");
    sessionStorage.removeItem("instructorId");
    localStorage.removeItem("jwtToken");
    navigate("/");
  };
  const siderStyle = {
    textAlign: 'center',
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: 'rgba(255,255,255,1)',
  };
  return (
    <div className="App">
      <Layout style={{

        minHeight: "100vh",
      }}>
        <Header className="App-header">

          <img
            src={LMTCLogo}
            alt="logo"
            style={{ height: "100%", float: "left", background: "white" }}
            onClick={() => {
              window.location = window.location.origin
            }}
          />
          <div
            style={{
              height: "100%",
              float: "left",
              display: "flex",
              flexDirection: "column",
              padding: "1rem",
            }}
          >
            <div style={{ lineHeight: "1rem" }}>LMTC</div>

            <div style={{ lineHeight: "1rem" }}>eMS</div>
          </div>
          {checkAuth() && (
            <div
              style={{
                float: "right",
              }}
            >
              <Button type="primary" onClick={handleLogout}>
                Logout
              </Button>
            </div>
          )}
        </Header>
        <Layout hasSider>
          {checkAuth() && (
            <Sider style={siderStyle}>
              <Menu
                onClick={onClick}
                defaultSelectedKeys={[location.pathname.replace("/", "")]}
                defaultOpenKeys={["dashboard"]}
                mode="inline"
                items={items}
              />
            </Sider>
          )}
          <Container className="ContentContainer">
            <AppRoutes />
          </Container>
        </Layout>
      </Layout>
    </div>
  );
}

export default InstructorApp;

