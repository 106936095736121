import axios from "../../../../api/axios";

export const getAdminsApi = async () => {
  try {
    const response = await axios.get("/admin/admins");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addAdminApi = async (adminData) => {
  try {
    const response = await axios.post("/admin/admins", adminData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateAdminApi = async (id, adminData) => {
  try {
    const response = await axios.put(`/admin/admins/${id}`, adminData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteAdminApi = async (id) => {
  try {
    const response = await axios.delete(`/admin/admins/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
