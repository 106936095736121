import React, { useState } from "react";
import { changePasswordApi } from "../../api/authApi";
import "./ChangePassword.css";
import { Button, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import FormGenerator from "../../../../components/FormGenerator";
import { INSTRUCTOR_ID } from "../../config";

const ChangePassword = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    const handleSubmit = async ({ newPassword, confirmNewPassword }) => {
        try {
            if (confirmNewPassword !== newPassword) {
                throw new Error("Passwords do not match")
            }
            const success = await changePasswordApi(INSTRUCTOR_ID(), newPassword);
            if (success) {
                alert("Password changed successfully"); 
            } else {
                setError("An error occurred. Please try again");
            }
        } catch (err) {
            setError(err);
        }
    };

    const fields = [
        { name: "newPassword", label: "New Password", type: "password" },
        { name: "confirmNewPassword", label: "Confirm New Password", type: "password" },
        { name: "submit_btn", label: "Submit", type: "button" },
    ];
    return (
        <div className="ChangePassword">
            <h2>Change Password</h2>
            <FormGenerator fields={fields} onSubmit={handleSubmit} />
            {error && <p>{error}</p>}
        </div>
    );
};

export default ChangePassword;