import React, { useState } from "react";
import FormGenerator from "../../../../components/FormGenerator";
import { Modal } from "antd";
import { createBatchApi } from "./api";

const AddBatchModal = ({ addBatchModalIsOpen, toggleAddBatchModal, instructorList, studentTypes }) => {
  const handleAddBatchSubmit = async (data) => {
    await createBatchApi(data);
    alert("Batch Added");
    toggleAddBatchModal(false);
    window.location.reload();
  };

  const fields = [
    { name: "batch_name", label: "Batch Name", type: "text" },
    { name: "batch_name_en", label: "Batch Name (in English)", type: "text" },
    { name: "studentTypeId", label: "Student Type", type: "select", options: studentTypes.map(studentType => ({ label: `${studentType.type_of_training}/ ${studentType.category}`, value: studentType.id })) },
    { name: "coordinatorId", label: "Coordinator", type: "select", options: instructorList.map(instructor => ({ label: `${instructor.name}/ ${instructor.email}`, value: instructor.id })), required: false },
    { name: "last_cert_no", label: "Certificate Number Starts At", type: "number",  required: false },
    { name: "executiveDirectorId", label: "Executive Director", type: "select", options: instructorList.map(instructor => ({ label: `${instructor.name}/ ${instructor.email}`, value: instructor.id })), required: false },
    { name: "add_batch", label: "Add Batch", type: "button" },
  ];

  return (
    <Modal
      open={addBatchModalIsOpen}
      onCancel={() => toggleAddBatchModal(false)}
      footer={false}
      width={"50%"}
    >
      <h2>Add Batch Form</h2>
      <FormGenerator fields={fields} onSubmit={handleAddBatchSubmit} />
    </Modal>
  );
};

export default AddBatchModal;
