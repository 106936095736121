import NepaliDate from "nepali-date-converter";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";

export const provinces = [
  "कोशी",
  "मधेश",
  "बागमती",
  "गण्डकी",
  "लुम्बिनि",
  "कर्णाली",
  "सुधुर पश्चिम",
];
export const designationList = [
  "अमिन",
  "सर्भेक्षक",
  "नापी अधिकृत",
  "प्रमुख नापी अधिकृत",
  "खरिदार",
  "नायव सुब्बा",
  "कम्प्युटर अपरेटर",
];

export const STUDENT_TYPE_MAP = {
  "senior%20(open)": 1,
  "senior%20(seniority)": 2,
  "junior%20inservice": 3,
  "junior-fresh": 4,
  "30%20working%20days": 5,
  "2%20weeks": 6,
  "1%20week": 7,
  "3%20day": 8,
  Other: 9,
};
export const checkAuth = () => {
  return sessionStorage.getItem("loggedIn");
};
export const checkAdminAuth = () => {
  const role = sessionStorage.getItem("role");
  return role;
};
export const removeNonAlphanumericChars = (string) => {
  return string.replace(/[^a-z0-9]/gi, "");
};

export const convertToNepaliDate = (date) => {
  return NepaliDate.fromAD(new Date(date)).format("YYYY-MM-DD");
};

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}
const createDocument = async (file, data) => {
  return new Promise((resolve, reject) => {
    loadFile(file, function (error, content) {
      if (error) {
        reject(error);
      }
      var zip = new PizZip(content);
      var doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });
      doc.setData(data);
      try {
        // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
        doc.render();
      } catch (error) {
        // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
        function replaceErrors(key, value) {
          if (value instanceof Error) {
            return Object.getOwnPropertyNames(value).reduce(function (
              error,
              key
            ) {
              error[key] = value[key];
              return error;
            },
            {});
          }
          return value;
        }
        console.log(JSON.stringify({ error: error }, replaceErrors));

        if (error.properties && error.properties.errors instanceof Array) {
          const errorMessages = error.properties.errors
            .map(function (error) {
              return error.properties.explanation;
            })
            .join("\n");
          console.log("errorMessages", errorMessages);
          // errorMessages is a humanly readable message looking like this :
          // 'The tag beginning with "foobar" is unopened'
        }
        reject(error);
      }
      var out = doc.getZip().generate({
        // type: "nodebuffer"
        type: "blob",
        // mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        // mimeType: 'application/pdf',

        // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      }); //Output the document using Data-URI
      resolve(out);
    });
  });
};

export const generateShortTermCertificate = async (
  student,
  returnBlob = false
) => {
  const data = {
    tsde: new Date(student.training_started_at).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }), //training_start_date_en formatted to Month DD, YYYY
    tede: new Date(student.graduated_at).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }), //training_end_date_en formatted to Month DD, YYYY
    tsdn: convertToNepaliDate(student.training_started_at).replace(/-/g, "/"), //training_start_date_np
    tedn: convertToNepaliDate(student.graduated_at).replace(/-/g, "/"), //training_end_date_np
    tn: student.batch_name, //training_name
    tne: student.batch_name_en, //training_name
    snn: student.name_np, //student_name_np
    sne: student.name, //student_name_en
    tc: student.coordinator_name, //training_coordinator
    ed: student.executive_director_name, //executive_director
    // ide: new Date().toISOString().split("T")[0].replace(/-/g, "/"), //issue_date_en
    // idn: convertToNepaliDate(new Date()).replace(/-/g, "/"), //issue_date_np
    ide: new Date(student.graduated_at).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }),
    idn: convertToNepaliDate(new Date(student.graduated_at)).replace(/-/g, "/"),
    cn: student.cert_no || 0,
    ce: student.cert_no || 0,
  };
  const outBlob = await createDocument("/shortterm_certificate.docx", data);
  if (returnBlob) {
    return outBlob;
  } else {
    // const directoryHandle = await window.showDirectoryPicker();
    // writeToFile(outBlob,`${student.batch_name}_${student.name}_${student.id}.docx`, directoryHandle)
    saveAs(outBlob, `${student.batch_name}_${student.name}_${student.id}.docx`);
  }
};
export const generateLongTermCertificate = async (
  student,
  returnBlob = false
) => {
  console.log(student);
  const data = {
    tsde: new Date(student.training_started_at).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }), //training_start_date_en formatted to Month DD, YYYY
    tede: new Date(student.graduated_at).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }), //training_end_date_en formatted to Month DD, YYYY
    tsdn: convertToNepaliDate(student.training_started_at).replace(/-/g, "/"), //training_start_date_np
    tedn: convertToNepaliDate(student.graduated_at).replace(/-/g, "/"), //training_end_date_np
    tn: student.batch_name, //training_name
    tne: student.batch_name_en, //training_name_en
    snn: student.name_np, //student_name_np
    pdn: student.division_np, //division of the results in nepali
    pde: student.division_en, // division of the results in english
    sne: student.name, //student_name_en
    tc: student.coordinator_name, //training_coordinator
    ed: student.executive_director_name, //executive_director
    // ide: new Date().toISOString().split("T")[0].replace(/-/g, "/"), //issue_date_en
    // idn: convertToNepaliDate(new Date()).replace(/-/g, "/"), //issue_date_np
    ide: new Date(student.graduated_at).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }),
    idn: convertToNepaliDate(new Date(student.graduated_at)).replace(/-/g, "/"),
    cn: student.cert_no || 0,
    ce: student.cert_no || 0,
  };
  console.log(data);
  const outBlob = await createDocument("/longterm_certificate.docx", data);
  if (returnBlob) {
    return outBlob;
  } else {
    saveAs(
      outBlob,
      `${student.batch_name}_${student.name}_${student.id}_certificate.docx`
    );
  }
};

export const generateLongTermTranscript = async (
  student,
  marks,
  returnBlob = false
) => {
  const data = {
    sne: student.name.toUpperCase(),
    tn: student.batch_name,
    tsde: new Date(student.training_started_at).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }), //training_start_date_en formatted to Month DD, YYYY
    tede: new Date(student.graduated_at).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }), //training_end_date_en formatted to Month DD, YYYY
    tsdn: convertToNepaliDate(student.training_started_at).replace(/-/g, "/"), //training_start_date_np
    tedn: convertToNepaliDate(student.graduated_at).replace(/-/g, "/"), //training_end_date_np
    marks: marks.map((x, idx) => ({
      sn: idx + 1,
      sb: x.subjectName,
      fm: x.full_marks || 100,
      pm: x.pass_marks || 40,
      mo: x.examMark + x.internalMark,
      rs: x.subjectRemarks || "",
    })),
    pde: student.division_en,
    gt: student.grand_total,
    pyn: convertToNepaliDate(student.graduated_at).split("-")[0], // passed year nepali
    pye: new Date(student.graduated_at).getFullYear(), // passed year english
    pt: student.percentage,
    ctn: student.citizenship_number,
    ctd: student.district,
    // ide: new Date().toISOString().split("T")[0].replace(/-/g, "/"), //issue_date_en
    // idn: convertToNepaliDate(new Date()).replace(/-/g, "/"), //issue_date_np
    ide: new Date(student.graduated_at).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }),
    idn: convertToNepaliDate(new Date(student.graduated_at)).replace(/-/g, "/"),
    cn: student.cert_no || 0,
  };
  const outBlob = await createDocument("/longterm_transcript.docx", data);
  if (returnBlob) {
    return outBlob;
  } else {
    // const pdf = await PDFDocument.load(await outBlob.arrayBuffer());
    saveAs(
      outBlob,
      `${student.batch_name}_${student.name}_${student.id}_transcript.docx`
    );
  }
};

export const getDivision = (marks) => {
  const DIVISION = {
    80: {
      en: "Distinction",
      np: "विशिष्ट",
      everyMin: 60,
      fypMin: 75,
    },
    70: {
      en: "First",
      np: "प्रथम",
      everyMin: 50,
      fypMin: 65,
    },
    55: {
      en: "Second",
      np: "द्वितीय",
      everyMin: 40,
      fypMin: 65,
    },
    40: {
      en: "Third",
      np: "तृतीय",
      everyMin: 40,
      fypMin: 50,
    },
  };
  const fypMark = marks.find((x) => x.is_fyp);
  if (typeof fypMark == "undefined") {
    alert("FYP mark is not found for student");
  }
  // Check if any has reexam
  const hasReexam = marks.some((x) => x.reexamMark != null);
  // Get total
  const grand_total = marks.reduce(
    (total, mark) =>
      total + mark.internalMark + (mark.reexamMark || mark.examMark),
    0
  );
  // Calculate the percentage
  const percentage = parseFloat(
    ((grand_total / (100 * marks.length)) * 100).toFixed(2)
  );
  let req_division = {};
  // Loop through the DIVISION object
  const keys = Object.keys(DIVISION).reverse();
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i];
    // Check if the percentage is greater than or equal to the key
    if (percentage >= key) {
      req_division = DIVISION[key];
      if (i <= 2) {
        // Check if there is a reexam and if the marks are less than 60
        if (hasReexam) {
          req_division = DIVISION[55];
        } else {
          if (
            !marks.every(
              (x) =>
                (x.reexamMark || x.examMark) + x.internalMark >=
                (DIVISION[key]["everyMin"] || 0)
            )
          ) {
            req_division = DIVISION[keys[i + 1]];
          }
          if (
            typeof fypMark != "undefined" &&
            fypMark["examMark"] < (DIVISION[key]["fypMin"] || 0)
          ) {
            req_division = DIVISION[keys[i + 1]];
          }
        }
      }
      break;
    }
  }
  if (Object.keys(req_division).length == 0)
    // If no division is found, set it to "Third"
    req_division = {
      en: "Third",
      np: "तृतीय",
    };
  // Return the required division
  return { req_division, grand_total, percentage };
};

export const writeToFile = async (blob, fileName, directoryHandle) => {
  const fileHandle = await directoryHandle.getFileHandle(fileName, {
    create: true,
  });
  const writable = await fileHandle.createWritable();
  await writable.write(blob);
  await writable.close();
};
export const mapNumberToSymbols = (number) => {
  const symbolMap = {
    1: "!",
    2: "@",
    3: "#",
    4: "$",
    5: "%",
    6: "^",
    7: "&",
    8: "*",
    9: "(",
    0: ")",
    "/": ".",
  };

  // Convert the number to a string and replace each digit with its symbol
  return number
    .toString()
    .split("")
    .map((digit) => symbolMap[digit] || digit)
    .join("");
};
