import React, { useState, useEffect, useCallback } from "react";

import { getAllBatchesApi, getInstructorsApi, getSubjectsApi } from "./api";
import SubjectTable from "./SubjectTable";
import AddSubjectModal from "./AddSubjectModal";
import { Button, Spin } from "antd";
import { STUDENT_TYPE_MAP } from "../../../../utils";
import { getStudentsApi } from "../Student/api";
import StudentModal from "./StudentModal";
import { getExamSubjectMarksApi } from "../Exam/api";

const Subject = () => {
  const [loading, setLoading] = useState(false);
  const [subjectList, setSubjectList] = useState([]);
  const [fullSubjectList, setFullSubjectList] = useState([]);
  const [activeBatchId, setActiveBatchId] = useState(null);
  const [activeSubjectId, setActiveSubjectId] = useState(null);

  const [studentList, setStudentList] = useState([]);
  const [fullStudentList, setFullStudentList] = useState([]);
  const [batches, setBatches] = useState([]);

  const [error, setError] = useState(null);

  const [addSubjectModalIsOpen, setAddSubjectModalIsOpen] = useState(false);
  const [studentModalIsOpen, setStudentModalIsOpen] = useState(false);

  const toggleAddSubjectModal = (status) => {
    setAddSubjectModalIsOpen(status || !addSubjectModalIsOpen);
  };

  const filterByStudentType = () => {
    const hash = window.location.hash;
    const reqStudentType = STUDENT_TYPE_MAP[hash.replace("#", "")] || 0;
    if (reqStudentType) {
      setSubjectList(
        fullSubjectList.filter((x) => x.batch_studentTypeId == reqStudentType)
      );
    }
  };
  useEffect(() => {
    filterByStudentType();
  }, [window.location.hash, fullSubjectList]);

  const filterStudentsByBatch = () => {
    if (activeBatchId) {
      setStudentList(fullStudentList.filter((x) => x.batchId == activeBatchId));
    }
  };
  useEffect(() => {
    filterStudentsByBatch();
  }, [activeBatchId]);

  const addSubjectMarksDataToStudentList = async () => {
    if (activeSubjectId) {
      const subjectMarks = await getExamSubjectMarksApi(activeSubjectId);
      setStudentList(
        fullStudentList
          .filter((x) => x.batchId == activeBatchId)
          .map((x) => {
            return {
              ...x,
              internalMark:
                subjectMarks.find((y) => y.studentId === x.id)?.internalMark ||
                "",
              examMark:
                subjectMarks.find((y) => y.studentId === x.id)?.examMark || "",
            };
          })
      );
    } else {
      setStudentList(
        fullStudentList
          .filter((x) => x.batchId == activeBatchId)
          .map((x) => {
            return {
              ...x,
              internalMark: "",
              examMark: "",
            };
          })
      );
    }
  };
  useEffect(() => {
    addSubjectMarksDataToStudentList();
  }, [activeSubjectId]);

  const fetchStudents = useCallback(async () => {
    try {
      const data = await getStudentsApi();
      setStudentList(data);
      setFullStudentList(data);
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
  }, []);

  const fetchSubjects = useCallback(async () => {
    try {
      const data = await getSubjectsApi();
      setSubjectList(data);
      setFullSubjectList(data);
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
  }, []);
  useEffect(() => {
    setLoading(true);
    fetchSubjects();
    setLoading(false);
  }, [fetchSubjects]);

  useEffect(() => {
    setLoading(true);
    fetchStudents();
    setLoading(false);
  }, [fetchStudents]);

  const [instructorList, setInstructorList] = useState([]);

  const fetchInstructors = useCallback(async () => {
    try {
      const data = await getInstructorsApi();
      setInstructorList(data);
    } catch (err) {
      setError(
        "An error occurred while fetching instructors. Please try again."
      );
    }
  }, []);

  useEffect(() => {
    fetchInstructors();
  }, [fetchInstructors]);

  useEffect(() => {
    const fetchBatches = async () => {
      const fetchedBatches = await getAllBatchesApi();
      setBatches(fetchedBatches);
    };
    fetchBatches();
  }, []);
  return (
    <>
      <h2>Module List</h2>
      <Button
        onClick={() => toggleAddSubjectModal(true)}
        style={{ float: "right", marginBottom: "10px" }}
      >
        Add Module
      </Button>
      <AddSubjectModal
        addSubjectModalIsOpen={addSubjectModalIsOpen}
        toggleAddSubjectModal={toggleAddSubjectModal}
        instructorList={instructorList}
        batches={batches}
      />
      {!loading ? (
        <SubjectTable
          subjectList={subjectList}
          instructorList={instructorList}
          batches={batches}
          setStudentModalIsOpen={setStudentModalIsOpen}
          setActiveBatchId={setActiveBatchId}
          setActiveSubjectId={setActiveSubjectId}
        />
      ) : (
        <Spin />
      )}
      <StudentModal
        studentList={studentList}
        batches={batches}
        isModalOpen={studentModalIsOpen}
        toggleModal={setStudentModalIsOpen}
      />
    </>
  );
};
export default Subject;
