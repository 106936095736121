import React, { useState, useEffect } from "react";
import FormGenerator from "../../../../components/FormGenerator";
import { Modal } from "antd";
import { addSubjectApi, getAllBatchesApi, getInstructorsApi } from "./api";

const AddSubjectModal = ({ addSubjectModalIsOpen, toggleAddSubjectModal, instructorList, batches }) => {
  if (typeof instructorList == "undefined") {
    instructorList = []
  }
  if (typeof batches == "undefined") {
    batches = []
  }
  const handleAddSubjectSubmit = async (data) => {
    await addSubjectApi(data);
    alert("Subject Added");
    toggleAddSubjectModal(false);
    window.location.reload();
  };
  const fields = [
    { name: "name", label: "Name", type: "text" },
    { name: "subject_code", label: "Module Code", type: "text" },
    {
      name: "batchId",
      label: "Batch",
      type: "select",
      options: batches.map((batch) => ({ value: batch.id, label: batch.batch_name })),
    },
    { name: "start_date", label: "Start Date", type: "date" },
    { name: "end_date", label: "End Date", type: "date" },
    { name: "full_exam_marks", label: "Full Exam Marks", type: "number" },
    { name: "full_internal_marks", label: "Full Internal Marks", type: "number" },
    { name: "coordinatorId", label: "Coordinator", type: "select", options: instructorList.map(instructor => ({ label: instructor.name, value: instructor.id })) },
    { name: "add_subject", label: "Add Module", type: "button" },
    { name: "is_fyp", label: "Is FYP", type: "checkbox", required: false },
  ];

  return (
    <Modal
      open={addSubjectModalIsOpen}
      onCancel={() => toggleAddSubjectModal(false)}
      footer={false}
      width={"50%"}
    >
      <h2>Add Module Form</h2>
      <FormGenerator fields={fields} onSubmit={handleAddSubjectSubmit} />
    </Modal>
  );
};

export default AddSubjectModal;
