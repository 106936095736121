import axios from "../../../../api/axios";
import { STUDENT_ID } from "../../config";
export const getStudent = async () => {
    try {
        const res = await axios.get(`/student/${STUDENT_ID()}`);
        return res.data;
    } catch (err) {
        console.error(err);
        return false;
    }
};
export const getStudentBatchInfo = async () => {
    try {
        const res = await axios.get(`/student/${STUDENT_ID()}/batch`);
        return res.data;
    } catch (err) {
        console.error(err);
        return false;
    }
};
