import axios from "../../../../api/axios";
import { INSTRUCTOR_ID } from "../../config";
export const getExamsApi = async () => {
  try {
    const response = await axios.get(`/instructor/${INSTRUCTOR_ID()}/exams`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getExamSubjectMarksApi = async (examSubjectId) => {
  try {
    const response = await axios.get(`/instructor/exams/subject/${examSubjectId}/marks`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getExamSubjectInternalMarksApi = async (examSubjectId)=>{
  try {
    const response = await axios.get(`/instructor/exams/subject/${examSubjectId}/internalmarks`);
    return response.data;
  } catch (error) {
    throw error;
  }

}

export const updateExamSubjectMarksApi = async (markId, markData) => {
  if (markData["internalMark"]) markData.internalMark = parseFloat(markData.internalMark)
  if (markData["subjectiveMark"]) markData.subjectiveMark = parseFloat(markData.subjectiveMark)
  if (markData["objectiveMark"]) markData.objectiveMark = parseFloat(markData.objectiveMark)
  
  markData.instructorId = parseInt(INSTRUCTOR_ID())
  try {
    const response = await axios.post(`/instructor/marks/${markId}`, { fields: markData });
    return response.data;
  } catch (error) {
    throw error;
  }
}

