import React, { useState, useEffect, useCallback } from "react";
import { addOldRecordApi, addOldRecordsMultipleApi, getOldRecordsApi } from "./api";
import OldRecordsTable from "./OldRecordsTable";
import { Button, List, Modal, Spin, Upload } from "antd";
import * as XLSX from 'xlsx';
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { API_BASE } from "../../../../api"; import axios from "../../../../api/axios";

const OldRecords = () => {
    const [loading, setLoading] = useState(false);
    const [oldRecordsList, setOldRecordsList] = useState([]);
    const [error, setError] = useState(null);

    const fetchOldRecords = useCallback(async () => {
        try {
            const data = await getOldRecordsApi();
            setOldRecordsList(data);
        } catch (err) {
            setError("An error occurred. Please try again.");
        }
    }, []);

    useEffect(() => {
        setLoading(true)
        fetchOldRecords()
        setLoading(false)
    }, [fetchOldRecords]);


    const beforeUpload = (file) => {
        const fileType = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        return fileType || Upload.LIST_IGNORE;
    }
    const onChange = (info) => {
        console.log(info);
        const formData = new FormData();
        formData.append('record', info.file.originFileObj);
        axios.put(API_BASE + '/admin/old-records/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        const file = info.file.originFileObj;
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsnames = wb.SheetNames.slice(0);
            wsnames.forEach(wsname => {
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: "", raw: true });

                if (data.length > 0) {
                    let req_payload = {
                        training_name: data[0][0],
                        training_date: data[1][0],
                        training_group: data[2][0],
                    }
                    data.slice(4).forEach(x => {
                        req_payload = {
                            ...req_payload,
                            certificate_number: x[2],
                            name: x[3],
                            citizenship_number: x[2],
                            district: x[8]
                        }
                        addOldRecordApi(req_payload)
                    })

                }
            });
            window.location.reload()
        };
        reader.readAsBinaryString(file);
    }

    
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fileList, setFileList] = useState([]);

    const fetchFiles = async () => {
        try {
            const response = await axios.get(API_BASE + '/admin/old-records/files');
            setFileList(response.data);
        } catch (err) {
            console.error(err);
        }
    };

    const handleDownloadClick = () => {
        fetchFiles();
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <h2>Old Records List</h2>
            <Button type="button" icon={<DownloadOutlined />} onClick={handleDownloadClick} />
            <Modal title="Files" visible={isModalVisible} onCancel={handleModalClose} footer={null}>
                <List
                    dataSource={fileList}
                    renderItem={item => (
                        <List.Item>
                            <a href={`${API_BASE}/uploads/old_records/${item}`} download>{item}</a>
                        </List.Item>
                    )}
                />
            </Modal>
            <Upload onChange={(info) => onChange(info)} beforeUpload={beforeUpload} method="get" action={"#"} >
                <Button type="button" icon={<UploadOutlined />} />
            </Upload>
            
            {!loading ? <OldRecordsTable oldRecordsList={oldRecordsList} /> : <Spin />}
        </>
    );
};

export default OldRecords;
