import React, { useState, useEffect } from "react";
import FormGenerator from "../../../../components/FormGenerator";
import { Modal } from "antd";
import { addStudentApi, getAllBatchesApi, uploadProfilePictureApi } from "./api";
import { provinces, designationList } from "../../../../utils";

const AddStudentModal = ({ addStudentModalIsOpen, toggleAddStudentModal, batches }) => {

  const handleAddStudentSubmit = async (data) => {
    data['uid'] = "pisId" in data ? data['pisId'] : ("email" in data ? data['email'] : `${data['name'].toLowerCase().replace(" ", ".")}_${data['mobile_number']}`)
    const {studentId} = await addStudentApi(data);
    if (studentId) {
      await uploadProfilePictureApi(studentId,data)
      alert("Student Added");
      toggleAddStudentModal(false);
      window.location.reload();
    }
  };
  const fields = [
    { name: "name", label: "Name", type: "text" },
    { name: "name_np", label: "Name (in Nepali)", type: "text", required: false },
    { name: "email", label: "Email", type: "text" },
    { name: "address", label: "Address", type: "text", required: false },
    { name: "date_of_birth", label: "Date of Birth", type: "date", required: false },
    {
      name: "batchId",
      label: "Batch",
      type: "select",
      options: batches.map((batch) => ({ value: batch.id, label: batch.batch_name })),
    },
    { name: "designation", label: "Designation", type: "select_datalist", required: false, options: designationList.map(x => ({ text: x, value: x })) },
    { name: "office", label: "Office", type: "text", required: false },
    { name: "mobile_number", label: "Mobile Number", type: "text", required: false },
    { name: "pisId", label: "PIS ID", type: "text", required: false },
    { name: "province", label: "Province", type: "select", required: false, options: provinces.map(x => ({ text: x, value: x })) },
    { name: "citizenship_number", label: "Citizenship Number", type: "text", required: false },
    { name: "district", label: "Issue District", type: "text", required: false },
    { name: "date_of_issue", label: "Issue Date", type: "text", required: false },
    {
      name: "gender", label: "Gender",
      type: "select",
      options: ['Male', 'Female', 'Other'].map((x) => ({ value: x, label: x })),
    },
    { name: "password", label: "Password", type: "password" },
    { name: "photo", label: "Photo", type: "file", required: false },
    { name: "add_student", label: "Add Student", type: "button" },
  ];

  return (
    <Modal
      open={addStudentModalIsOpen}
      onCancel={() => toggleAddStudentModal(false)}
      footer={false}
      width={"50%"}
    >
      <h2>Add Student Form</h2>
      <FormGenerator fields={fields} onSubmit={handleAddStudentSubmit} />
    </Modal>
  );
};

export default AddStudentModal;
