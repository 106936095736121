import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, BrowserRouter as Router, Routes } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";
import "./index.css";
import AdminApp from "./pages/Admin/App";
import InstructorApp from "./pages/Instructor/App";
import StudentApp from "./pages/Student/App";
import HomePage from "./pages";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
    </BrowserRouter>
    <BrowserRouter basename="/admin">
      <AdminApp />
    </BrowserRouter>
    <BrowserRouter basename="/instructor">
      <InstructorApp />
    </BrowserRouter>

    <BrowserRouter basename="/student">
      <StudentApp />
    </BrowserRouter>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
