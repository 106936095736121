import React, { useState, useEffect } from "react";
import { Modal, Table, Button, Space } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { INSTRUCTOR_ID } from "../../config";
import { getExamSubjectInternalMarksApi, updateExamSubjectMarksApi } from "./api";
import EditableCell from "../../../../components/EditableCell";

const ExamSubjectInternalMarksModal = ({ examSubjectId, internalMarksModalIsOpen, toggleInternalMarksModal }) => {

    const [examSubjectInternalMarks, setExamSubjectInternalMarks] = useState([]);
    const [editingKey, setEditingKey] = useState();
    const getInternalMarks = async () => {
        const examSubjectInternalMarks = await getExamSubjectInternalMarksApi(examSubjectId);
        setExamSubjectInternalMarks(examSubjectInternalMarks)
    }
    useEffect(() => {
        if (examSubjectId && internalMarksModalIsOpen)
            getInternalMarks()
    }, [examSubjectId,internalMarksModalIsOpen]);

    const handleInternalMarkUpdate = async (markId, markData) => {
        await updateExamSubjectMarksApi(markId, markData)
        await getInternalMarks()
    }
    return (
        <Modal
            open={internalMarksModalIsOpen}
            onCancel={() => toggleInternalMarksModal(false)}
            footer={false}
            width={"70%"}
        >
            <h2>Internal Marks for Module</h2>
            <Table dataSource={examSubjectInternalMarks} bordered={true}>
                <Table.Column title="Student Name" dataIndex="studentName" key="studentName" />
                <Table.Column title="Student Email" dataIndex="studentEmail" key="studentEmail" />
                <Table.Column title="Module Name" dataIndex="subjectName" key="subjectName" />
                <Table.Column title="Module Code" dataIndex="subject_code" key="subject_code" />
                <Table.Column title="Internal Mark" dataIndex="internalMark" key="internalMark"
                    render={(text, record) => {
                        const recordCopy = { id: record.markId };
                        const editable = editingKey == record.markId;
                        return (
                            <EditableCell
                                editing={editable}
                                dataIndex="internalMark"
                                title="Internal Mark"
                                inputType="number"
                                record={recordCopy}
                                originalValue={text}
                                updateApi={handleInternalMarkUpdate}
                                setEditingKey={setEditingKey}
                                shouldNotReload={true}
                            >
                                {text}
                            </EditableCell>
                        );
                    }} />
                <Table.Column
                    title="Action"
                    key="action"
                    render={(_, record) => (
                        <Space size="middle">
                            {(record.instructorId == INSTRUCTOR_ID() || record.instructorId == null) && <Button onClick={() => setEditingKey(record.markId)}>
                                <EditOutlined />
                            </Button>}
                        </Space>
                    )}
                />
            </Table>
        </Modal>
    );
};

export default ExamSubjectInternalMarksModal;
