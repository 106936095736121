import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import { getAverageMarksPerSubjectInExamApi, getBatchesStatusApi, getStudentCountByGenderApi, getStudentCountByProvinceApi } from './api';
import { Col, Divider, Row, Spin, Table, Tag } from 'antd';
import { getAllBatchesApi } from '../Batch/api';
import { CSVLink } from "react-csv";
import { DownloadOutlined } from '@ant-design/icons';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend, ArcElement
);
const Dashboard = () => {
    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState(null);
    const [studentCountData, setStudentCountData] = useState(null);
    const [studentGenderCountData, setStudentGenderCountData] = useState(null);
    const [batchStatusData, setBatchStatusData] = useState(null);

    const [completedBatchStatusData, setCompletedBatchStatusData] = useState([])
    const [inprogressBatchStatusData, setInprogressBatchStatusData] = useState([])
    const [upcomingBatchStatusData, setUpcomingBatchStatusData] = useState([])

    const batchListColumns = [
        {
            title: 'Batch Name',
            dataIndex: 'batch_name',
            key: 'batch_name',
            render: (text) => <Tag color='cyan'>{text}</Tag>,
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            render: (text) => <Tag color='blue'>{text}</Tag>,
        },
        // {
        //     title: 'Gender',
        //     dataIndex: 'gender',
        //     key: 'gender',
        // },
        // {
        //     title: 'Province',
        //     dataIndex: 'province',
        //     key: 'province',
        // },
        {
            title: 'Total Students',
            dataIndex: 'total',
            key: 'total',
            render: (text) => <Tag color='green'>{text}</Tag>,
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => <Tag color='purple'>{text}</Tag>,
        },
        {
            title: 'Completed At',
            dataIndex: 'completed_at',
            key: 'completed_at',
            render: (text) => <Tag color='orange'>{text}</Tag>,
        },]


    const studentType = useRef(window.location.hash)
    useEffect(() => {
        studentType.current = decodeURI(window.location.hash).replace("#", "")
    }, [window.location.hash])
    const [batchList, setBatchList] = useState([]);
    const fetchBatches = useCallback(async () => {
        try {
            const data = await getAllBatchesApi();
            setBatchList(data);
        } catch (err) {
            console.error("An error occurred. Please try again.");
        }
    }, []);
    useEffect(() => {
        fetchBatches();
    }, [fetchBatches]);

    useEffect(() => {
        const fetchAverageMarks = async () => {
            let data = await getAverageMarksPerSubjectInExamApi();
            if (studentType.current) {
                data = data.filter(x => x.category.toLowerCase() == studentType.current)
            }
            if (typeof data != "undefined" && data) {
                const labels = data.map(item => item.subject_name);
                const averages = data.map(item => item.average_mark);

                setChartData({
                    labels: labels,
                    datasets: [
                        {
                            label: 'Average Marks',
                            data: averages,
                            borderWidth: 2,
                            backgroundColor: averages.map((_, i) => `hsla(${i * (360 / averages.length)}, 70%, 50%, 0.6)`),
                            hoverBackgroundColor: averages.map((_, i) => `hsla(${i * (360 / averages.length)}, 70%, 50%, 0.4)`),
                            hoverBorderColor: averages.map((_, i) => `hsla(${i * (360 / averages.length)}, 70%, 50%, 1)`),
                        }
                    ]
                });
            }
        };
        function sumTotalsByProvince(data) {
            const provinceTotals = {};

            data.forEach(entry => {
                const province = entry.province;
                const total = entry.total;

                if (provinceTotals.hasOwnProperty(province)) {
                    provinceTotals[province] += total;
                } else {
                    provinceTotals[province] = total;
                }
            });

            return Object.keys(provinceTotals).map(province => {
                return { province: province, total: provinceTotals[province] };
            });
        }
        const fetchStudentCountByProvince = async () => {
            let data = await getStudentCountByProvinceApi();
            data = data.filter(x => x.province)
            if (studentType.current) {
                data = data.filter(x => x.category.toLowerCase() == studentType.current)
            }
            data = sumTotalsByProvince(data)
            if (typeof data != "undefined" && data) {
                const labels = data.map(item => item.province);
                const counts = data.map(item => item.total);

                setStudentCountData({
                    labels: labels,
                    datasets: [
                        {
                            label: 'Student Count',
                            data: counts,
                            borderWidth: 2,
                            backgroundColor: counts.map((_, i) => `hsla(${i * (360 / counts.length)}, 70%, 50%, 0.6)`),
                            hoverBackgroundColor: counts.map((_, i) => `hsla(${i * (360 / counts.length)}, 70%, 50%, 0.4)`),
                            hoverBorderColor: counts.map((_, i) => `hsla(${i * (360 / counts.length)}, 70%, 50%, 1)`),
                        }
                    ]
                });
            }
        };

        function sumTotalsByGender(data) {
            const genderTotals = {};

            data.forEach(entry => {
                const gender = entry.gender;
                const total = entry.total;

                if (genderTotals.hasOwnProperty(gender)) {
                    genderTotals[gender] += total;
                } else {
                    genderTotals[gender] = total;
                }
            });

            return Object.keys(genderTotals).map(gender => {
                return { gender: gender, total: genderTotals[gender] };
            });
        }
        const fetchStudentCountByGender = async () => {
            let data = await getStudentCountByGenderApi();
            data = data.filter(x => x.gender)
            if (studentType.current) {
                data = data.filter(x => x.category.toLowerCase() == studentType.current)
            }
            data = sumTotalsByGender(data)
            if (typeof data != "undefined" && data) {
                const labels = data.map(item => item.gender);
                const counts = data.map(item => item.total);

                setStudentGenderCountData({
                    labels: labels,
                    datasets: [
                        {
                            label: 'Student Count',
                            data: counts,
                            borderWidth: 2,
                            backgroundColor: counts.map((_, i) => `hsla(${i * (360 / counts.length)}, 70%, 50%, 0.6)`),
                            hoverBackgroundColor: counts.map((_, i) => `hsla(${i * (360 / counts.length)}, 70%, 50%, 0.4)`),
                            hoverBorderColor: counts.map((_, i) => `hsla(${i * (360 / counts.length)}, 70%, 50%, 1)`),
                        }
                    ]
                });
            }

        }
        const fetchbatchStatusData = async () => {
            let data = await getBatchesStatusApi();
            if (studentType.current) {
                data = data.filter(x => x.category.toLowerCase() == studentType.current)
            }
            if (typeof data != "undefined" && data) {
                setBatchStatusData(data);
                setCompletedBatchStatusData(data.filter(batch => batch.completed_at && new Date(batch.completed_at) < new Date()))
                setInprogressBatchStatusData(data.filter(batch => !batch.completed_at || new Date(batch.completed_at) > new Date()))
                setUpcomingBatchStatusData(data.filter(batch => new Date(batch.created_at) > new Date()))
            }

        }
        fetchAverageMarks();
        fetchStudentCountByProvince();
        fetchStudentCountByGender();
        fetchbatchStatusData()
    }, [window.location.hash]);

    return (
        <div className='Dashboard'>
            {loading ? <Spin /> :
                <Row>
                    <Col xl={12} span={24}>
                        {chartData && <Bar
                            data={chartData}
                            options={{
                                indexAxis: 'y',
                                elements: {
                                    bar: {
                                        borderWidth: 2,
                                    },
                                },
                                responsive: true,
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    title: {
                                        display: true,
                                        text: 'Average Marks Per Module in Every Exam',
                                    },
                                },
                            }}
                        />}
                    </Col>
                    <Col xl={12} span={24}>
                        {studentCountData && <Bar
                            data={studentCountData}
                            options={{
                                indexAxis: 'y',
                                elements: {
                                    bar: {
                                        borderWidth: 2,
                                    },
                                },
                                responsive: true,
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    title: {
                                        display: true,
                                        text: 'Total Number of Students by Province',
                                    },
                                },
                            }}
                        />}
                    </Col>
                    <Col xl={12} span={24} style={{ textAlign: "center", alignContent: "center", padding: "0 10%" }}>
                        {studentGenderCountData && <Pie
                            data={studentGenderCountData}
                            options={{
                                responsive: true,
                                plugins: {
                                    legend: {
                                        position: 'right',
                                    },
                                    title: {
                                        display: true,
                                        text: 'Total Number of Students by Gender',
                                    },
                                },
                            }}
                        />}
                    </Col>
                    <Col xl={24} span={24} style={{ textAlign: "left", paddingLeft: "5%" }}>
                        <h1><u>Training Status</u></h1>

                        {batchStatusData && <>
                            <Row>
                                <Col xl={12} span={24}>
                                    <h2>Completed</h2>
                                </Col>
                                <Col xl={12} span={24} style={{

                                    display: "flex",
                                    "alignItems": "center",
                                    "justifyContent": "end"
                                }}>
                                    <CSVLink
                                        data={completedBatchStatusData.map(batch => ({
                                            batch_name: batch.batch_name,
                                            category: batch.category,
                                            total: batch.total,
                                            created_at: batch.created_at,
                                            completed_at: batch.completed_at || ""
                                        }))}
                                        filename={"completed_batch_status_data.csv"}
                                        style={{
                                            fontSize: "x-large"
                                        }}
                                    >
                                        <DownloadOutlined />
                                    </CSVLink>
                                </Col>
                            </Row>
                            <Table bordered dataSource={completedBatchStatusData} columns={batchListColumns} />
                            <Row>
                                <Col span={6}>
                                    <Pie
                                        data={{
                                            labels: completedBatchStatusData.map(batch => batch.category),
                                            datasets: [{
                                                data: completedBatchStatusData.map(batch => batch.total),
                                                backgroundColor: [
                                                    '#FF6384',
                                                    '#36A2EB',
                                                    '#FFCE56'
                                                ],
                                                hoverBackgroundColor: [
                                                    '#FF6384',
                                                    '#36A2EB',
                                                    '#FFCE56'
                                                ]
                                            }]
                                        }}
                                        options={{
                                            responsive: true,
                                            legend: {
                                                position: 'right',
                                            },
                                            title: {
                                                display: true,
                                                text: 'Batch Status Data',
                                            },
                                        }}
                                    />
                                </Col>
                                <Col span={10} offset={4} style={{
                                    display: 'flex',
                                    alignItems: "center"
                                }}>
                                    <Bar
                                        data={{
                                            labels: completedBatchStatusData.map(batch => batch.batch_name),
                                            datasets: [{
                                                data: completedBatchStatusData.map(batch => batch.total),
                                                backgroundColor: [
                                                    '#FF6384',
                                                    '#36A2EB',
                                                    '#FFCE56'
                                                ],
                                                hoverBackgroundColor: [
                                                    '#FF6384',
                                                    '#36A2EB',
                                                    '#FFCE56'
                                                ]
                                            }]
                                        }}
                                        options={{
                                            responsive: true,
                                            plugins: {
                                                legend: {
                                                    display: false
                                                },
                                                title: {
                                                    display: true,
                                                    text: 'Batch Status Data',
                                                },
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>


                            <Row>
                                <Col xl={12} span={24}>
                                    <h2>In Progress</h2>
                                </Col>
                                <Col xl={12} span={24} style={{

                                    display: "flex",
                                    "alignItems": "center",
                                    "justifyContent": "end"
                                }}>
                                    <CSVLink
                                        data={inprogressBatchStatusData.map(batch => ({
                                            batch_name: batch.batch_name,
                                            category: batch.category,
                                            total: batch.total,
                                            created_at: batch.created_at,
                                            completed_at: batch.completed_at || ""
                                        }))}
                                        filename={"in_progress_batch_status_data.csv"}
                                        style={{
                                            fontSize: "x-large"
                                        }}
                                    >
                                        <DownloadOutlined />
                                    </CSVLink></Col>
                            </Row>
                            <Table bordered dataSource={inprogressBatchStatusData} columns={batchListColumns} />
                            <Row>
                                <Col span={6}>
                                    <Pie
                                        data={{
                                            labels: inprogressBatchStatusData.map(batch => batch.category),
                                            datasets: [{
                                                data: inprogressBatchStatusData.map(batch => batch.total),
                                                backgroundColor: [
                                                    '#FF6384',
                                                    '#36A2EB',
                                                    '#FFCE56'
                                                ],
                                                hoverBackgroundColor: [
                                                    '#FF6384',
                                                    '#36A2EB',
                                                    '#FFCE56'
                                                ]
                                            }]
                                        }}
                                        options={{
                                            responsive: true,
                                            legend: {
                                                position: 'right',
                                            },
                                            title: {
                                                display: true,
                                                text: 'Batch Status Data',
                                            },
                                        }}
                                    />
                                </Col>
                                <Col span={10} offset={4} style={{
                                    display: 'flex',
                                    alignItems: "center"
                                }}>
                                    <Bar
                                        data={{
                                            labels: inprogressBatchStatusData.map(batch => batch.batch_name),
                                            datasets: [{
                                                data: inprogressBatchStatusData.map(batch => batch.total),
                                                backgroundColor: [
                                                    '#FF6384',
                                                    '#36A2EB',
                                                    '#FFCE56'
                                                ],
                                                hoverBackgroundColor: [
                                                    '#FF6384',
                                                    '#36A2EB',
                                                    '#FFCE56'
                                                ]
                                            }]
                                        }}
                                        options={{
                                            responsive: true,
                                            plugins: {
                                                legend: {
                                                    display: false
                                                },
                                                title: {
                                                    display: true,
                                                    text: 'Batch Status Data',
                                                },
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={12} span={24}>
                                    <h2>Upcoming</h2>
                                </Col>
                                <Col xl={12} span={24} style={{

                                    display: "flex",
                                    "alignItems": "center",
                                    "justifyContent": "end"
                                }}>
                                    <CSVLink
                                        data={upcomingBatchStatusData.map(batch => ({
                                            batch_name: batch.batch_name,
                                            category: batch.category,
                                            total: batch.total,
                                            created_at: batch.created_at,
                                            completed_at: batch.completed_at || ""
                                        }))}
                                        filename={"upcoming_batch_status_data.csv"}
                                        style={{
                                            fontSize: "x-large",
                                        }}
                                    >
                                        <DownloadOutlined />
                                    </CSVLink>
                                </Col>
                            </Row>
                            <Table bordered dataSource={upcomingBatchStatusData} columns={batchListColumns} />
                            <Row>
                                <Col span={4}>
                                    <Pie
                                        data={{
                                            labels: upcomingBatchStatusData.map(batch => batch.category),
                                            datasets: [{
                                                data: upcomingBatchStatusData.map(batch => batch.total),
                                                backgroundColor: [
                                                    '#FF6384',
                                                    '#36A2EB',
                                                    '#FFCE56'
                                                ],
                                                hoverBackgroundColor: [
                                                    '#FF6384',
                                                    '#36A2EB',
                                                    '#FFCE56'
                                                ]
                                            }]
                                        }}
                                        options={{
                                            responsive: true,
                                            legend: {
                                                position: 'right',
                                            },
                                            title: {
                                                display: true,
                                                text: 'Batch Status Data',
                                            },
                                        }}
                                    />
                                </Col>
                                <Col span={10} offset={4} style={{
                                    display: 'flex',
                                    alignItems: "center"
                                }}>{upcomingBatchStatusData.length > 0 &&
                                    <Bar
                                        data={{
                                            labels: upcomingBatchStatusData.map(batch => batch.batch_name),
                                            datasets: [{
                                                data: upcomingBatchStatusData.map(batch => batch.total),
                                                backgroundColor: [
                                                    '#FF6384',
                                                    '#36A2EB',
                                                    '#FFCE56'
                                                ],
                                                hoverBackgroundColor: [
                                                    '#FF6384',
                                                    '#36A2EB',
                                                    '#FFCE56'
                                                ]
                                            }]
                                        }}
                                        options={{
                                            responsive: true,
                                            plugins: {
                                                legend: {
                                                    display: false
                                                },
                                                title: {
                                                    display: true,
                                                    text: 'Batch Status Data',
                                                },
                                            }
                                        }}
                                    />}
                                </Col>
                            </Row>
                        </>}
                    </Col>
                </Row>
            }
        </div>
    );
};

export default Dashboard;
