import React, { useState } from "react";
import { Button, Popconfirm, Space, Table, Upload, message } from "antd";
import {
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import EditableCell from "../../../../components/EditableCell";
import { updateBatchApi, getStudentsByBatchApi, deleteBatchApi } from "./api";
import { addStudentApi, getStudentMarksApi } from "../Student/api";
import {
  convertToNepaliDate,
  generateLongTermCertificate,
  generateLongTermTranscript,
  generateShortTermCertificate,
  getDivision,
  writeToFile,
} from "../../../../utils";
import * as XLSX from "xlsx";
const { Column } = Table;

const BatchTable = ({ instructorList, batchList, studentTypes }) => {
  if (typeof instructorList == "undefined") instructorList = [];
  const [editingKey, setEditingKey] = useState();
  const deleteConfirm = async (e) => {
    await deleteBatchApi(e);
    message.success("Batch Deleted");
    setTimeout(() => {
      window.location.reload();
    }, 250);
  };
  const beforeUpload = (file) => {
    const fileType =
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!fileType) {
      message.error(`${file.name} is not a xlsx file`);
    }
    return fileType || Upload.LIST_IGNORE;
  };
  const onChange = (info, batchId) => {
    const file = info.file.originFileObj;
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      // const data = XLSX.utils.sheet_to_json(ws, { header: 1,blankrows:false,defval:"",raw:true });
      const xlsxToAttributeMap = {
        "नाम थर": "name_np",
        "Name in English": "name",
        Designation: "designation",
        Office: "office",
        "Mobile number": "mobile_number",
        "PIS(ID)": "pisId",
        "Email*": "email",
        // "uid": "email",
        Province: "province",
        Address: "address",
        Gender: "gender",
        "Citizenship Number": "citizenship_number",
        "Issue District": "district",
        "Issue Date": "date_of_issue",
      };
      let requests = data.map(async (x) => {
        if ("S.N" in x && x["S.N"]) {
          let payload = {};
          Object.keys(xlsxToAttributeMap).forEach((key) => {
            if (key in x && x[key]) {
              payload[xlsxToAttributeMap[key]] = x[key];
            }
          });
          if (!"mobile_number" in payload) {
            payload["mobile_number"] = "";
          }
          payload["uid"] =
            "pisId" in payload
              ? payload["pisId"]
              : "email" in payload
              ? payload["email"]
              : `${payload["name"].toLowerCase().replace(" ", ".")}_${
                  payload["mobile_number"]
                }`;
          payload["password"] = "password";
          payload["batchId"] = batchId;
          try {
            return addStudentApi(payload);
          } catch (e) {
            console.error(e);
          }
        }
      });
      Promise.all(requests)
        .then(() => {
          alert("Batch Addition Process Complete");
        })
        .catch((err) => {
          console.error(err);
        });
    };
    reader.readAsBinaryString(file);
  };
  return (
    <Table dataSource={batchList} bordered={true}>
      <Column
        title="Batch Name"
        dataIndex={"batch_name"}
        key={"batch_name"}
        filterMode={"menu"}
        filterSearch={true}
        filters={[
          ...new Map(
            batchList.map((x) => [
              x["batch_name"],
              { text: x.batch_name, value: x.batch_name },
            ])
          ).values(),
        ]}
        onFilter={(value, record) => record.batch_name === value}
        render={(text, record) => {
          const editable = editingKey == record.id;
          const recordCopy = { ...record };

          return (
            <EditableCell
              editing={editable}
              dataIndex="batch_name"
              title="Batch Name"
              inputType="text"
              record={recordCopy}
              originalValue={text}
              updateApi={updateBatchApi}
              setEditingKey={setEditingKey}
            >
              {`${text}`}
            </EditableCell>
          );
        }}
      />
      <Column
        title="Batch Name (en)"
        dataIndex={"batch_name_en"}
        key={"batch_name_en"}
        render={(text, record) => {
          const editable = editingKey == record.id;
          const recordCopy = { ...record };

          return (
            <EditableCell
              editing={editable}
              dataIndex="batch_name_en"
              title="Batch Name (en)"
              inputType="text"
              record={recordCopy}
              originalValue={text}
              updateApi={updateBatchApi}
              setEditingKey={setEditingKey}
            >
              {`${text}`}
            </EditableCell>
          );
        }}
      />
      <Column
        title="Student Type"
        dataIndex={"studentTypeId"}
        key={"studentTypeId"}
        render={(text, record) => {
          const editable = editingKey == record.id;
          const recordCopy = { ...record };

          return (
            <EditableCell
              editing={editable}
              dataIndex="studentTypeId"
              title="Student Type"
              inputType="select"
              record={recordCopy}
              originalValue={text}
              updateApi={updateBatchApi}
              setEditingKey={setEditingKey}
              selectOptions={studentTypes.map((x) => ({
                value: x.id,
                label: `${x.type_of_training}: ${x.category}`,
              }))}
            >
              {`${record.type_of_training}: ${record.category}`}
            </EditableCell>
          );
        }}
      />
      <Column
        title="Coordinator Name/ Email"
        dataIndex={"coordinatorId"}
        key={"coordinatorId"}
        render={(text, record) => {
          const editable = editingKey == record.id;
          const recordCopy = { ...record };

          return (
            <EditableCell
              editing={editable}
              dataIndex="coordinatorId"
              title="Coordinator"
              inputType="select"
              record={recordCopy}
              originalValue={text}
              updateApi={updateBatchApi}
              setEditingKey={setEditingKey}
              selectOptions={instructorList.map((x) => ({
                value: x.id,
                label: `${x.name}/ ${x.email}`,
              }))}
            >
              {`${record.coordinatorName}/ ${record.coordinatorEmail}`}
            </EditableCell>
          );
        }}
      />
      <Column
        title="Executive Director Name/ Email"
        dataIndex={"executiveDirectorId"}
        key={"executiveDirectorId"}
        render={(text, record) => {
          const editable = editingKey == record.id;
          const recordCopy = { ...record };

          return (
            <EditableCell
              editing={editable}
              dataIndex="executiveDirectorId"
              title="Executive Director"
              inputType="select"
              record={recordCopy}
              originalValue={text}
              updateApi={updateBatchApi}
              setEditingKey={setEditingKey}
              selectOptions={instructorList.map((x) => ({
                value: x.id,
                label: `${x.name}/ ${x.email}`,
              }))}
            >
              {`${record.executiveDirectorName}/ ${record.executiveDirectorEmail}`}
            </EditableCell>
          );
        }}
      />
      <Column
        title="Started At"
        dataIndex={"created_at"}
        key={"created_at"}
        render={(text, record) => {
          const editable = editingKey == record.id;
          const recordCopy = { ...record };

          if (text) {
            let dateParts = text.split(" ")[0].split("-");
            text = `${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`;
          }

          return (
            <EditableCell
              editing={editable}
              dataIndex="created_at"
              title="Started At"
              inputType="date"
              record={recordCopy}
              originalValue={text}
              updateApi={updateBatchApi}
              setEditingKey={setEditingKey}
            >
              {text && (
                <>
                  {text} / {text && convertToNepaliDate(text)}
                </>
              )}
            </EditableCell>
          );
        }}
      />
      <Column
        title="Last Certificate Number"
        dataIndex={"last_cert_no"}
        key={"last_cert_no"}
        render={(text, record) => {
          const editable = editingKey == record.id;
          const recordCopy = { ...record };
          return (
            <EditableCell
              editing={editable}
              dataIndex="last_cert_no"
              title="Last Certificate Number"
              inputType="number"
              record={recordCopy}
              originalValue={text}
              updateApi={updateBatchApi}
              setEditingKey={setEditingKey}
            >
              {text && <>{text}</>}
            </EditableCell>
          );
        }}
      />
      <Column
        title="Completed At"
        dataIndex={"completed_at"}
        key={"completed_at"}
        render={(text, record) => {
          const editable = editingKey == record.id;
          const recordCopy = { ...record };

          return (
            <EditableCell
              editing={editable}
              dataIndex="completed_at"
              title="Completed At"
              inputType="date"
              record={recordCopy}
              originalValue={text}
              updateApi={updateBatchApi}
              setEditingKey={setEditingKey}
            >
              {text && (
                <>
                  {text} / {text && convertToNepaliDate(text)}
                </>
              )}
            </EditableCell>
          );
        }}
      />
      <Column
        title="Action"
        key="action"
        render={(_, record) => (
          <Space size="middle">
            {editingKey ? (
              <Button onClick={() => setEditingKey()}>
                <CloseOutlined />
              </Button>
            ) : (
              <Button onClick={() => setEditingKey(record.id)}>
                <EditOutlined />
              </Button>
            )}

            <Upload
              onChange={(info) => onChange(info, record.id)}
              beforeUpload={beforeUpload}
              method="get"
              action={"#"}
            >
              <Button type="button" icon={<UploadOutlined />} />
            </Upload>
            <Button
              onClick={async () => {
                try {
                  const students = await getStudentsByBatchApi(record.id);
                  const directoryHandle = await window.showDirectoryPicker();

                  if (record.type_of_training != "Short-term") {
                    for (let student of students) {
                      const marks = await getStudentMarksApi(student.id);
                      if (marks && marks.length > 0) {
                        const { req_division, percentage, grand_total } =
                          getDivision(marks);

                        student.division_np = req_division["np"];
                        student.division_en = req_division["en"];
                        student.grand_total = grand_total;
                        student.percentage = percentage;
                        // setMarks(marks)
                        // toggleMarksModal(true)
                        const certBlob = await generateLongTermCertificate(
                          student,
                          true
                        );
                        const transcriptBlob = await generateLongTermTranscript(
                          student,
                          marks,
                          true
                        );

                        await writeToFile(
                          certBlob,
                          `${student.batch_name}_${student.name}_${student.id}_certificate.docx`,
                          directoryHandle
                        );
                        await writeToFile(
                          transcriptBlob,
                          `${student.batch_name}_${student.name}_${student.id}_transcript.docx`,
                          directoryHandle
                        );
                      }
                    }
                  } else {
                    for (let student of students) {
                      const certBlob = await generateShortTermCertificate(
                        student,
                        true
                      );
                      await writeToFile(
                        certBlob,
                        `${student.batch_name}_${student.name}_${student.id}.pdf`,
                        directoryHandle
                      );
                    }
                  }
                } catch (error) {
                  console.error(error);
                }
              }}
              size="small"
            >
              <DownloadOutlined />
            </Button>

            <Popconfirm
              title="Delete the batch"
              description="Are you sure to delete this batch?"
              onConfirm={() => deleteConfirm(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button danger>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        )}
      />
    </Table>
  );
};

export default BatchTable;
