import React, { useState } from 'react';
import { Modal } from 'antd';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column', // Changed from 'row' to 'column'
        backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        margin: 'auto',
        flexDirection: 'row'
    },
    tableCol: {
        width: '33%',
        borderStyle: 'solid',
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: 10
    },
    tableColHeader: {
        width: '33%',
        borderStyle: 'solid',
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: '#D3D3D3',
        padding: 10,
        fontWeight: 'bold'
    }
});

// Create Document Component
const MyDocument = ({ student, marks }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            {marks.length == 0 &&
                <View>
                    <Text>Character Certificate</Text>
                </View>}
            <View style={styles.section}>
                <Text>{`Student Name: ${student.name}`}</Text>
                <Text>{`Student Email: ${student.email}`}</Text>
                <Text>{`Student Address: ${student.address}`}</Text>
                <Text>{`Student Date of Birth: ${student.date_of_birth}`}</Text>
            </View>
            {marks.length > 0 && <View style={styles.section}>
                <Text>Exam Marks:</Text>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableColHeader}>Subject Name</Text>
                        <Text style={styles.tableColHeader}>Subject Code</Text>
                        <Text style={styles.tableColHeader}>Exam Mark</Text>
                        <Text style={styles.tableColHeader}>Internal Mark</Text>
                    </View>
                    {marks.map((mark, index) => (
                        <View key={index} style={styles.tableRow}>
                            <Text style={styles.tableCol}>{mark.subjectName}</Text>
                            <Text style={styles.tableCol}>{mark.subject_code}</Text>
                            <Text style={styles.tableCol}>{mark.examMark}</Text>
                            <Text style={styles.tableCol}>{mark.internalMark}</Text>
                        </View>
                    ))}
                </View>
            </View>
            }
            <View style={{ ...styles.section, flexGrow: 10 }} />
        </Page>
    </Document >
);

const StudentMarksModal = ({ student, marks, isModalOpen, toggleModal }) => {
    return (
        <Modal
            title="Student Marks"
            open={isModalOpen}
            onCancel={() => toggleModal(false)}
            width={"100vw"}
            height={"100vh"}
            style={{ top: 0 }}
        >
            <PDFViewer width={"100%"} height={"768"} style={{ flex: 1 }}>
                <MyDocument student={student} marks={marks} />
            </PDFViewer>
        </Modal>
    );
};

export default StudentMarksModal;
// No code needs to be inserted here.
