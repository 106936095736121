import React, { useState, useEffect, useRef, useCallback } from "react";

import { getInstructorRolesApi, getInstructorsApi, getAllSubjectsApi } from "./api";
import InstructorTable from "./InstructorTable";
import AddInstructorModal from "./AddInstructorModal";
import { Button, Spin } from "antd";

const Instructor = () => {
    const [loading, setLoading] = useState(false);
    const [instructorList, setInstructorList] = useState([]);
    const [error, setError] = useState(null);

    const [subjects, setSubjects] = useState([]);
    const [instructorRoles, setInstructorRoles] = useState([]);

    const fetchSubjects = useCallback(async () => {
        const response = await getAllSubjectsApi();
        if (typeof response != "undefined" && response) {
            setSubjects(response);
        } else {
            setError("An error occurred while fetching subjects. Please try again.");
        }
    }, []);
    const fetchInstructorRoles = useCallback(async () => {
        const response = await getInstructorRolesApi();
        if (typeof response != "undefined" && response) {
            setInstructorRoles(response);
        } else {
            setError("An error occurred while fetching subjects. Please try again.");
        }
    }, []);
    useEffect(() => {
        fetchSubjects();
    }, [fetchSubjects]);
    useEffect(() => {
        fetchInstructorRoles();
    }, [fetchInstructorRoles]);

    const [addInstructorModalIsOpen, setAddInstructorModalIsOpen] = useState(false);
    const toggleAddInstructorModal = (status) => {
        setAddInstructorModalIsOpen(status || !addInstructorModalIsOpen);
    };

    const fetchInstructors = useCallback(async () => {
        try {
            const data = await getInstructorsApi();
            setInstructorList(data);
        } catch (err) {
            setError("An error occurred. Please try again.");
        }
    }, []);
    useEffect(() => {
        setLoading(true)
        fetchInstructors();
        setLoading(false)
    }, [fetchInstructors]);
    return (
        <>
            <h2>Instructor List</h2>
            <Button
                onClick={() => toggleAddInstructorModal(true)}
                style={{ float: "right", marginBottom: "10px" }}
            >
                Add Instructor
            </Button>
            <AddInstructorModal
                addInstructorModalIsOpen={addInstructorModalIsOpen}
                toggleAddInstructorModal={toggleAddInstructorModal}
                subjects={subjects || []}
                instructorRoles={instructorRoles || []}
            />
            {!loading ? <InstructorTable instructorList={instructorList} subjects={subjects || []} instructorRoles={instructorRoles} /> : <Spin />}

        </>
    );
};
export default Instructor;

