import React, { useState, useEffect } from "react";
import { Modal, Table, } from "antd";
import { getExamMarksApi } from "./api";

const ExamSubjectMarksModal = ({ examId, marksModalIsOpen, toggleMarksModal }) => {

    const [examMarks, setExamMarks] = useState([]);
    const [editingKey, setEditingKey] = useState();
    const getMarks = async () => {
        const examMarks = await getExamMarksApi(examId);
        setExamMarks(examMarks)
    }
    useEffect(() => {
        if (examId) getMarks()
    }, [examId]);

    return (
        <Modal
            open={marksModalIsOpen}
            onCancel={() => toggleMarksModal(false)}
            footer={false}
            width={"70%"}
        >
            <h2>Marks for Exam Module ID</h2>
            <Table dataSource={examMarks} bordered={true}>
                <Table.Column title="Module" dataIndex="subjectName" key="subjectName" />
                <Table.Column title="Module Code" dataIndex="subject_code" key="subject_code" />
                <Table.Column title="Exam Mark" dataIndex="examMark" key="examMark" />
                <Table.Column title="Internal Mark" dataIndex="internalMark" key="internalMark" />
            </Table>
        </Modal>
    );
};

export default ExamSubjectMarksModal;
