import React, { useCallback, useEffect, useState } from "react";
import { changePasswordApi, getStudentDetailsApi } from "../../api/authApi";
import "./Profile.css";
import { Avatar, Button, Col, Form, Image, Input, Row } from "antd";
import { useNavigate } from "react-router-dom";
import FormGenerator from "../../../../components/FormGenerator";
import { STUDENT_ID } from "../../config";
import { API_BASE } from "../../../../api";
import { uploadProfilePictureApi, uploadCitizenshipPictureApi } from "./api";

const Profile = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    const [studentData, setStudentData] = useState()

    const fetchStudentDetails = useCallback(async () => {
        try {
            const data = await getStudentDetailsApi(STUDENT_ID());
            setStudentData(data);
        } catch (err) {
            setError("An error occurred. Please try again.");
        }
    }, []);
    useEffect(() => {
        fetchStudentDetails();
    }, [fetchStudentDetails]);

    const handleSubmit = async ({ newPassword, confirmNewPassword }) => {
        try {
            if (confirmNewPassword !== newPassword) {
                throw new Error("Passwords do not match")
            }
            const success = await changePasswordApi(STUDENT_ID(), newPassword);
            if (success) {
                alert("Password changed successfully");
            } else {
                setError("An error occurred. Please try again");
            }
        } catch (err) {
            setError(err);
        }
    };
    const handlePhotoUpload = async (data) => {
        try {
            await uploadProfilePictureApi(STUDENT_ID(), data)
            alert("Profile Picture Changed")
            // window.location.reload()
        } catch (err) {
            setError(err);
        }
    };
    const handleCitizenshipPhotoUpload = async (data) => {
        try {
            await uploadCitizenshipPictureApi(STUDENT_ID(), data)
            alert("Citizenship Picture Changed")
            // window.location.reload()
        } catch (err) {
            setError(err);
        }
    };

    return (
        <div className="Profile">
            <h2>Profile</h2>
            <input
                type="file"
                id="profile_photo"
                name="photo"
                accept="image/png, image/jpeg"
                style={{ display: 'none' }}
                onChange={(e) => {
                    const file = e.target.files[0];
                    handlePhotoUpload({ photo: file });
                }}
            />
            <label htmlFor="profile_photo">
                <Avatar
                    size={128}
                    src={studentData && studentData.photo_url ? `${API_BASE}/${studentData.photo_url}` : null}
                />
            </label>

            <br />
            <br />
            <br />

            <h3>Citizenship Photo</h3>
            <Image height={124} src={studentData && studentData.citizenship_photo_url ? `${API_BASE}/${studentData.citizenship_photo_url}` : null} />
            <FormGenerator fields={[
                { name: "citizenship_photo", label: "Citizenship Photo", type: "file" },
                { name: "submit_btn", label: "Submit", type: "button" },
            ]} onSubmit={handleCitizenshipPhotoUpload} />
            {error && <p>{error}</p>}


            <br />
            <br />
            <br />

            <h3>Change Password</h3>
            <FormGenerator fields={[
                { name: "newPassword", label: "New Password", type: "password" },
                { name: "confirmNewPassword", label: "Confirm New Password", type: "password" },
                { name: "submit_btn", label: "Submit", type: "button" },
            ]} onSubmit={handleSubmit} />
            {error && <p>{error}</p>}

        </div>
    );
};

export default Profile;