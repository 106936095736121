import React, { useState, useEffect, useCallback } from "react";

import { getExamsApi } from "./api";
import ExamTable from "./ExamTable";
import { Button } from "antd";

const Exam = () => {
  const [examList, setExamList] = useState([]);
  const [error, setError] = useState(null);

  const [addExamModalIsOpen, setAddExamModalIsOpen] = useState(false);
  const toggleAddExamModal = (status) => {
    setAddExamModalIsOpen(status || !addExamModalIsOpen);
  };

  const fetchExams = useCallback(async () => {
    try {
      const data = await getExamsApi();
      setExamList(data);
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
  }, []);
  useEffect(() => {
    fetchExams();
  }, [fetchExams]);
  return (
    <>
      <h2>Exam List</h2>
      <ExamTable examList={examList} />
    </>
  );
};
export default Exam;
