import React, { useState, useEffect, useCallback } from "react";

import { getAllBatchesApi, getInstructorsApi, getStudentTypesApi } from "./api";
import BatchTable from "./BatchTable";
import AddBatchModal from "./AddBatchModal";
import { Button, Spin } from "antd";
import { Link } from "react-router-dom";
import { STUDENT_TYPE_MAP } from "../../../../utils";

const Batch = () => {
  const [batchList, setBatchList] = useState([]);
  const [fullBatchList, setFullBatchList] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [addBatchModalIsOpen, setAddBatchModalIsOpen] = useState(false);
  const toggleAddBatchModal = (status) => {
    setAddBatchModalIsOpen(status || !addBatchModalIsOpen);
  };

  const filterByStudentType = () => {
    const hash = window.location.hash;
    const reqStudentType = STUDENT_TYPE_MAP[hash.replace("#", "")] || 0
    if (reqStudentType) {
      setBatchList(fullBatchList.filter(x => x.studentTypeId == reqStudentType))
    }
  }
  useEffect(() => {
    filterByStudentType()
  }, [window.location.hash, fullBatchList])
  const fetchBatches = useCallback(async () => {
    try {
      const data = await getAllBatchesApi();
      setBatchList(data);
      setFullBatchList(data)
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
  }, []);
  useEffect(() => {
    setLoading(true)
    fetchBatches();
    setLoading(false)
  }, [fetchBatches]);


  const [instructorList, setInstructorList] = useState([]);

  const fetchInstructors = useCallback(async () => {
    try {
      const data = await getInstructorsApi();
      setInstructorList(data);
    } catch (err) {
      setError("An error occurred while fetching instructors. Please try again.");
    }
  }, []);
  useEffect(() => {
    fetchInstructors();
  }, [fetchInstructors]);
  const [studentTypes, setStudentTypes] = useState([])

  useEffect(() => {
    const fetchStudentTypes = async () => {
      const studentTypes = await getStudentTypesApi();
      setStudentTypes(studentTypes);
    };
    fetchStudentTypes();
  }, []);

  return (
    <>
      <h2>Batch List</h2>
      <Button
        onClick={() => toggleAddBatchModal(true)}
        style={{ float: "right", marginBottom: "10px" }}
      >
        Add Batch
      </Button>
      <Button
        style={{ float: "right", marginBottom: "10px" }}
      >
        <Link to={`${window.location.origin}/STT_template.xlsx`} target="_blank" >Download Template</Link>
      </Button>
      <AddBatchModal
        addBatchModalIsOpen={addBatchModalIsOpen}
        toggleAddBatchModal={toggleAddBatchModal}
        instructorList={instructorList} studentTypes={studentTypes}
      />
      {!loading ? <BatchTable batchList={batchList} instructorList={instructorList} studentTypes={studentTypes} /> : <Spin />}

    </>
  );
};
export default Batch;
