import React, { useState } from "react";
import { Button, Popconfirm, Space, Table, message } from "antd";
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { deleteSubjectApi, updateSubjectApi } from "./api";
import EditableCell from "../../../../components/EditableCell";
import AddSubjectModal from "./AddSubjectModal";
const { Column } = Table;

const SubjectTable = ({
  subjectList,
  instructorList,
  batches,
  setStudentModalIsOpen,
  setActiveBatchId,
  setActiveSubjectId,
}) => {
  const [editingKey, setEditingKey] = useState();

  const deleteConfirm = async (e) => {
    await deleteSubjectApi(e);
    message.success("Module Deleted");
    setTimeout(() => {
      window.location.reload();
    }, 250);
  };
  return (
    <>
      <Table dataSource={subjectList} bordered={true}>
        <Column
          title="Name"
          dataIndex={"name"}
          key={"name"}
          render={(text, record) => {
            const recordCopy = { ...record };
            delete recordCopy.total_instructors;
            const editable = editingKey == record.id;
            return (
              <EditableCell
                editing={editable}
                dataIndex="name"
                title="Name"
                inputType="text"
                record={recordCopy}
                originalValue={text}
                updateApi={updateSubjectApi}
                setEditingKey={setEditingKey}
              >
                {text}
              </EditableCell>
            );
          }}
        />
        <Column
          title="Module Code"
          dataIndex={"subject_code"}
          key={"subject_code"}
          render={(text, record) => {
            const recordCopy = { ...record };
            delete recordCopy.total_instructors;
            const editable = editingKey == record.id;
            return (
              <EditableCell
                editing={editable}
                dataIndex="subject_code"
                title="Module Code"
                inputType="text"
                record={recordCopy}
                originalValue={text}
                updateApi={updateSubjectApi}
                setEditingKey={setEditingKey}
              >
                {text}
              </EditableCell>
            );
          }}
        />

        <Column
          title="Coordinator Name/ Email"
          dataIndex={"coordinatorId"}
          key={"coordinatorId"}
          render={(text, record) => {
            const editable = editingKey == record.id;
            const recordCopy = { ...record };

            return (
              <EditableCell
                editing={editable}
                dataIndex="coordinatorId"
                title="Coordinator"
                inputType="select"
                record={recordCopy}
                originalValue={text}
                updateApi={updateSubjectApi}
                setEditingKey={setEditingKey}
                selectOptions={instructorList.map((x) => ({
                  value: x.id,
                  label: `${x.name}/ ${x.email}`,
                }))}
              >
                {`${record.coordinator_name}/ ${record.coordinator_email}`}
              </EditableCell>
            );
          }}
        />
        <Column
          title="Batch"
          dataIndex={"batchId"}
          key={"batchId"}
          filterMode={"menu"}
          filterSearch={true}
          filters={[
            ...new Map(
              subjectList.map((x) => [
                x["batch_name"],
                { text: x.batch_name, value: x.batch_name },
              ])
            ).values(),
          ]}
          onFilter={(value, record) => record.batch_name === value}
          render={(text, record) => {
            const editable = editingKey == record.id;
            const recordCopy = { ...record };

            return (
              <EditableCell
                editing={editable}
                dataIndex="batchId"
                title="Batch"
                inputType="select"
                record={recordCopy}
                originalValue={record.batch_name}
                updateApi={updateSubjectApi}
                setEditingKey={setEditingKey}
                selectOptions={batches.map((x) => ({
                  value: x.id,
                  label: `${x.batch_name}`,
                }))}
              >
                {`${record.batch_name}`}
              </EditableCell>
            );
          }}
        />

        <Column
          title="Start Date"
          dataIndex={"start_date"}
          key={"start_date"}
          render={(text, record) => {
            const editable = editingKey == record.id;
            const recordCopy = { ...record };

            return (
              <EditableCell
                editing={editable}
                dataIndex="start_date"
                title="Start Date"
                inputType="date"
                record={recordCopy}
                originalValue={text}
                updateApi={updateSubjectApi}
                setEditingKey={setEditingKey}
              >
                {`${text}`}
              </EditableCell>
            );
          }}
        />
        <Column
          title="End Date"
          dataIndex={"end_date"}
          key={"end_date"}
          render={(text, record) => {
            const editable = editingKey == record.id;
            const recordCopy = { ...record };

            return (
              <EditableCell
                editing={editable}
                dataIndex="end_date"
                title="End Date"
                inputType="date"
                record={recordCopy}
                originalValue={text}
                updateApi={updateSubjectApi}
                setEditingKey={setEditingKey}
              >
                {`${text}`}
              </EditableCell>
            );
          }}
        />
        <Column
          title="Full Exam Marks"
          dataIndex={"full_exam_marks"}
          key={"full_exam_marks"}
          render={(text, record) => {
            const recordCopy = { ...record };
            delete recordCopy.total_instructors;
            const editable = editingKey == record.id;
            return (
              <EditableCell
                editing={editable}
                dataIndex="full_exam_marks"
                title="Full Exam Marks"
                inputType="number"
                record={recordCopy}
                originalValue={text}
                updateApi={updateSubjectApi}
                setEditingKey={setEditingKey}
              >
                {text}
              </EditableCell>
            );
          }}
        />

        <Column
          title="Full Internal Marks"
          dataIndex={"full_internal_marks"}
          key={"full_internal_marks"}
          render={(text, record) => {
            const recordCopy = { ...record };
            delete recordCopy.total_instructors;
            const editable = editingKey == record.id;
            return (
              <EditableCell
                editing={editable}
                dataIndex="full_internal_marks"
                title="Full Internal Marks"
                inputType="number"
                record={recordCopy}
                originalValue={text}
                updateApi={updateSubjectApi}
                setEditingKey={setEditingKey}
              >
                {text}
              </EditableCell>
            );
          }}
        />
        <Column
          title="Total Instructors"
          dataIndex={"total_instructors"}
          key={"total_instructors"}
        />
        <Column
          title="Created At"
          dataIndex={"created_at"}
          key={"created_at"}
        />
        <Column
          title="Action"
          key="action"
          render={(_, record) => (
            <Space size="middle">
              {editingKey ? (
                <Button onClick={() => setEditingKey()}>
                  <CloseOutlined />
                </Button>
              ) : (
                <Button onClick={() => setEditingKey(record.id)}>
                  <EditOutlined />
                </Button>
              )}

              <Popconfirm
                title="Delete the module"
                description="Are you sure to delete this module?"
                onConfirm={() => deleteConfirm(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button danger>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
              <Button
                onClick={() => {
                  setStudentModalIsOpen(true);
                  setActiveBatchId(record.batchId);
                  setActiveSubjectId(record.examSubjectId);
                }}
              >
                <UserOutlined />
              </Button>
            </Space>
          )}
        />
      </Table>
      <AddSubjectModal />
    </>
  );
};

export default SubjectTable;
