// frontend/src/pages/Admin/pages/BlockedStudents/BlockedStudents.js
import React, { useEffect, useState } from 'react';
import { getBlockedStudentsApi } from './api';
import BlockedStudentsTable from './BlockedStudentsTable';
import { Spin } from 'antd';

const BlockedStudents = () => {
  const [blockedStudentsList, setBlockedStudentsList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchBlockedStudents = async () => {
      const blockedStudents = await getBlockedStudentsApi();
      setBlockedStudentsList(blockedStudents);
    };

    setLoading(true)
    fetchBlockedStudents();
    setLoading(false)
  }, []);

  return (
    <div>
      <h2>Blocked Students</h2>
      {!loading ? <BlockedStudentsTable blockedStudentsList={blockedStudentsList} /> : <Spin/>}
      
    </div>
  );
};

export default BlockedStudents;