import AppRoutes from "./routes";
import "./App.css";

import React, { useEffect, useRef, useState } from "react";
import { MailOutlined } from "@ant-design/icons";
import { Button, Menu } from "antd";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";
import { Layout } from "antd"
import { checkAuth } from "../../utils";
import LMTCLogo from "../../static/lmtc_logo.png"

const { Footer, Header, Sider } = Layout;
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}


function AdminApp() {
  const studentType = useRef("")
  const [selectedKeys, setSelectedKeys] = useState([`${window.location.pathname.replace("/admin/", "").split("#")[0]}#`, window.location.hash])
  const role = sessionStorage.getItem("role");
  const items = [
    getItem("Dashboard", "dashboard-main", <MailOutlined />, [
      getItem("Dashboard", `dashboard#${studentType.current}`),
      getItem("Instructor", "instructors#"),
      getItem("Student", `students#${studentType.current}`),
      getItem("Module", `module#${studentType.current}`),
      getItem("Exams", `exams#${studentType.current}`),
      getItem("Batches", `batches#${studentType.current}`),
      getItem("Blocked Students", "blocked-students#"),
      getItem("Old Records", "old-records#"),
    ]),
    ...(role === "superadmin" ? [getItem("Super Admin", "super-admin", <MailOutlined />, [
      getItem("Admin List", "admins"),
    ])] : []),
    getItem("Student Type", "student-type", <MailOutlined />, [
      getItem("All", "#"),
      getItem("Senior (Open)", "#senior (open)"),
      getItem("Senior (Seniority)", "#senior (seniority)"),
      getItem("Junior Inservice", "#junior inservice"),
      getItem("Junior-fresh", "#junior-fresh"),
      getItem("30 working days", "#30 working days"),
      getItem("2 weeks", "#2 weeks"),
      getItem("1 week", "#1 week"),
      getItem("3 day", "#3 day"),
      getItem("Other", "#other"),
    ])
  ];

  const navigate = useNavigate();
  const onClick = (e) => {
    navigate(e.key);
  };
  const handleLogout = () => {
    sessionStorage.removeItem("loggedIn");
    localStorage.removeItem("jwtToken");
    navigate("/");
  };
  const siderStyle = {
    textAlign: 'center',
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: 'rgba(255,255,255,1)',
  };
  return (
    <div className="App">
      <Layout style={{

        minHeight: "100vh",
      }}>
        <Header className="App-header">
          <img
            src={LMTCLogo}
            alt="logo"
            style={{ height: "100%", float: "left", background: "white" }}
            onClick={() => {
              window.location = window.location.origin
            }}
          />
          <div
            style={{
              height: "100%",
              float: "left",
              display: "flex",
              flexDirection: "column",
              padding: "1rem",
            }}
          >
            <div style={{ lineHeight: "1rem" }}>LMTC</div>

            <div style={{ lineHeight: "1rem" }}>eMS</div>
          </div>
          {checkAuth() && (
            <div
              style={{
                float: "right",
              }}
            >
              <Button type="primary" onClick={handleLogout}>
                Logout
              </Button>
            </div>
          )}
        </Header>
        <Layout hasSider>
          {checkAuth() && (
            <Sider style={siderStyle}>
              <Menu
                onClick={onClick}
                defaultOpenKeys={["dashboard-main", 'student-type', 'super-admin']}
                selectedKeys={selectedKeys}
                mode="inline"
                items={items}
                onSelect={x => { x.keyPath[1] == 'student-type' && (studentType.current = window.location.hash.replace("#", "")); setSelectedKeys([`${window.location.pathname.replace("/admin/", "")}#${studentType.current}`, window.location.hash]); }}
              />
            </Sider>
          )}
          <div className="ContentContainer">
            <AppRoutes />
          </div>
        </Layout>
      </Layout>
    </div>
  );
}

export default AdminApp;

