import React, { useState } from 'react';
import { Modal } from 'antd';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import StudentDocument from './StudentDocument';

const StudentMarksModal = ({ student, marks, isModalOpen, toggleModal }) => {
    return (
        <Modal
            title="Student Marks"
            open={isModalOpen}
            onCancel={() => toggleModal(false)}
            width={"100vw"}
            height={"100vh"}
            style={{ top: 0 }}
        >
            <PDFViewer width={"100%"} height={"768"} style={{ flex: 1 }}>
                <StudentDocument student={student} marks={marks} />
            </PDFViewer>
        </Modal>
    );
};

export default StudentMarksModal;
// No code needs to be inserted here.
