import React, { useState, useEffect, useRef, useCallback } from "react";

import { getAllBatchesApi, getStudentsApi } from "./api";
import StudentTable from "./StudentTable";
import AddStudentModal from "./AddStudentModal";
import { Button, Spin } from "antd";
import { STUDENT_TYPE_MAP } from "../../../../utils";

const Student = () => {
  const [loading, setLoading] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [batches, setBatches] = useState([]);
  const [fullStudentList, setFullStudentList] = useState([]);
  const [error, setError] = useState(null);

  const [addStudentModalIsOpen, setAddStudentModalIsOpen] = useState(false);
  const toggleAddStudentModal = (status) => {
    setAddStudentModalIsOpen(status || !addStudentModalIsOpen);
  };

  const filterByStudentType = () => {
    const hash = window.location.hash;
    const reqStudentType = STUDENT_TYPE_MAP[hash.replace("#", "")] || 0
    if (reqStudentType) {
      setStudentList(fullStudentList.filter(x => x.studentTypeId == reqStudentType))
    }
  }
  useEffect(() => {
    filterByStudentType()
  }, [window.location.hash, fullStudentList])

  const fetchStudents = useCallback(async () => {
    try {
      const data = await getStudentsApi();
      setStudentList(data);
      setFullStudentList(data)
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
  }, []);
  useEffect(() => {
    setLoading(true)
    fetchStudents()
    setLoading(false)
  }, [fetchStudents]);


  useEffect(() => {
    const fetchBatches = async () => {
      const fetchedBatches = await getAllBatchesApi();
      setBatches(fetchedBatches);
    };
    fetchBatches();
  }, []);


  return (
    <>
      <h2>Student List</h2>
      <Button
        onClick={() => toggleAddStudentModal(true)}
        style={{ float: "right", marginBottom: "10px" }}
      >
        Add Student
      </Button>
      <AddStudentModal
        addStudentModalIsOpen={addStudentModalIsOpen}
        toggleAddStudentModal={toggleAddStudentModal}
        batches={batches}
      />
      {!loading ? <StudentTable studentList={studentList} batches={batches} /> : <Spin/>}
      

    </>
  );
};
export default Student;
