import React, { useState, useEffect } from "react";
import { loginApi } from "../../api/authApi";
import "./Login.css";
import { Button, Form, Input } from "antd";
import { checkAuth } from "../../../../utils";
import { useNavigate } from "react-router-dom";
import FormGenerator from "../../../../components/FormGenerator";

const Login = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (checkAuth()) navigate("/");
  }, []);
  const [error, setError] = useState(null);

  const handleSubmit = async ({ email, password }) => {
    try {
      const success = await loginApi(email, password);
      if (success) {
        navigate("/dashboard"); // If successful, redirect to item dispatch page
      } else {
        setError("Invalid username or password");
      }
    } catch (err) {
      setError("An error occurred. Please try again");
    }
  };

  const fields = [
    { name: "email", label: "Email", type: "email" },
    { name: "password", label: "Password", type: "password" },
    { name: "login_btn", label: "Login", type: "button" },
  ];
  return (
    <div className="Login">
      <h2>Admin Login</h2>
      <FormGenerator fields={fields} onSubmit={handleSubmit} />
      {error && <p>{error}</p>}
    </div>
  );
};

export default Login;
