import React, { useState, useEffect, useCallback } from "react";

import { getExamsApi } from "./api";
import ExamTable from "./ExamTable";
import AddExamModal from "./AddExamModal";
import { Button, Spin } from "antd";
import { STUDENT_TYPE_MAP } from "../../../../utils";

const Exam = () => {
  const [loading, setLoading] = useState(false);
  const [examList, setExamList] = useState([]);
  const [fullExamList, setFullExamList] = useState([]);
  const [error, setError] = useState(null);

  const [addExamModalIsOpen, setAddExamModalIsOpen] = useState(false);
  const toggleAddExamModal = (status) => {
    setAddExamModalIsOpen(status || !addExamModalIsOpen);
  };

  const filterByStudentType = () => {
    const hash = window.location.hash;
    const reqStudentType = STUDENT_TYPE_MAP[hash.replace("#", "")] || 0
    if (reqStudentType) {
      setExamList(fullExamList.filter(x => x.studentTypeId == reqStudentType))
    }
  }
  useEffect(() => {
    filterByStudentType()
  }, [window.location.hash, fullExamList])

  const fetchExams = useCallback(async () => {
    try {
      const data = await getExamsApi();
      setExamList(data);
      setFullExamList(data)
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
  }, []);
  useEffect(() => {
    setLoading(true)
    fetchExams();
    setLoading(false)
  }, [fetchExams]);
  return (
    <>
      <h2>Exam List</h2>
      <Button
        onClick={() => toggleAddExamModal(true)}
        style={{ float: "right", marginBottom: "10px" }}
      >
        Add Exam
      </Button>
      <AddExamModal
        addExamModalIsOpen={addExamModalIsOpen}
        toggleAddExamModal={toggleAddExamModal}
      />
      {!loading ?
        <ExamTable examList={examList} /> : <Spin />}
    </>
  );
};
export default Exam;
