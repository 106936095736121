import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Login from "./pages/Login/Login";
import { checkAdminAuth } from "../../utils";
import Student from "./pages/Student/Student";
import Instructor from "./pages/Instructor/Instructor";
import Subject from "./pages/Subject/Subject";
import Exam from "./pages/Exam/Exam";
import Dashboard from "./pages/Dashboard/Dashboard";
import Batch from "./pages/Batch/Batch";
import BlockedStudents from "./pages/BlockedStudents/BlockedStudents";
import OldRecords from "./pages/OldRecords/OldRecords";
import Admin from "./pages/Admin/Admin";

const ProtectedRoute = ({ children, role = "admin" }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      const currentUserRole = checkAdminAuth(); // replace with your function to check token
      console.log(currentUserRole);
      if (!(["admin", 'superadmin'].includes(currentUserRole))) navigate("/");
      if (role == "superadmin" && currentUserRole !== "superadmin") navigate("/")

    };

    checkToken();
  }, [navigate]);

  return children;
};

const AdminRoutes = () => (
  <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
    <Route path="/students" element={<ProtectedRoute><Student /></ProtectedRoute>} />
    <Route path="/instructors" element={<ProtectedRoute><Instructor /></ProtectedRoute>} />
    <Route path="/module" element={<ProtectedRoute><Subject /></ProtectedRoute>} />
    <Route path="/exams" element={<ProtectedRoute><Exam /></ProtectedRoute>} />
    <Route path="/batches" element={<ProtectedRoute><Batch /></ProtectedRoute>} />
    <Route path="/blocked-students" element={<ProtectedRoute><BlockedStudents /></ProtectedRoute>} />
    <Route path="/old-records" element={<ProtectedRoute><OldRecords /></ProtectedRoute>} />
    <Route path="/admins" element={<ProtectedRoute role="superadmin"><Admin /></ProtectedRoute>} />
  </Routes>
);

export default AdminRoutes;
