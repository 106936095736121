import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Login from "./pages/Login/Login";
import { checkAuth } from "../../utils";
import Exam from "./pages/Exam/Exam";
import Dashboard from "./pages/Dashboard/Dashboard";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Profile from "./pages/Profile/Profile";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      const isValid = checkAuth(); // replace with your function to check token
      if (!isValid) navigate("/");
    };

    checkToken();
  }, [navigate]);

  return children;
};

const InstructorRoutes = () => (
  <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/reset-password" element={<ForgotPassword />} />
    <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
    <Route path="/exams" element={<ProtectedRoute><Exam /></ProtectedRoute>} />
    <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
  </Routes>
);

export default InstructorRoutes;
