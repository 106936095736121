import { Button, Form, Input, Select } from "antd";
import React, { useState } from "react";

function FormGenerator({ fields, onSubmit }) {
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    let { name, value, type, checked, files } = e.target;
    if (type == "checkbox") value = checked ? 1 : 0;
    if (type == "file") value = files[0];
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    onSubmit(formData);
  };

  return (
    <Form onFinish={handleSubmit} labelCol={{ span: 4 }}>
      {fields.map((field) => {
        let { name, label, type, options, select_type, required } = field;
        if (typeof required == "undefined") required = true
        return (
          <Form.Item label={type != "button" ? label : ""} key={name}>
            {["text", "password", "date", 'email', 'number', 'checkbox'].includes(type) && (
              <Input
                type={type}
                name={name}
                value={formData[name] || ""}
                onChange={handleChange}
                required={required}
              />
            )}
            {["select"].includes(type) && (
              <Select
                mode={select_type}
                name={name}
                value={formData[name] || []}
                onChange={(e) =>
                  handleChange({
                    target: { name, value: e },
                  })
                }
                allowClear={true}
                required={required}
                options={options}
              />
            )}
            {['select_datalist'].includes(type) &&
              (<>
                <input type={"text"} defaultValue={formData[name] || ""} allowClear={true} list={name} className="ant-input-affix-wrapper css-dev-only-do-not-override-1w4v3hc ant-input" />
                <datalist id={name}>
                  {options.map(x => <option>{x.value}</option>)}
                </datalist>
              </>)}
            {["button"].includes(type) && (
              <Button htmlType="submit" type="primary">
                {label}
              </Button>
            )}
            {["file"].includes(type) && (
              <Input
                type={type}
                name={name}
                onChange={handleChange}
                required={required}
              />
            )}
          </Form.Item>
        );
      })}
    </Form>
  );
}

export default FormGenerator;
