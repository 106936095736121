import React, { useState } from 'react';
import { Modal } from 'antd';
import StudentTable from '../Student/StudentTable';

const StudentModal = ({ studentList, batches, isModalOpen, toggleModal }) => {
  return (
    <Modal
      title="Student List"
      open={isModalOpen}
      onCancel={() => toggleModal(false)}
      footer={null}
      width="80%"
    >
      <StudentTable studentList={studentList} batches={batches} hideColumns={['email', 'citizenship_number','district','date_of_issue','designation']} showMarks={true}/>
    </Modal>
  );
};

export default StudentModal;

