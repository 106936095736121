import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select } from "antd";
import { SaveOutlined } from "@ant-design/icons";

const EditableCell = ({
  editing,
  dataIndex,
  inputType,
  record,
  originalValue,
  updateApi,
  setEditingKey,
  selectOptions,
  shouldNotReload,
  children,
  ...restProps
}) => {
  const [childrenState, setChildrenState] = useState(children);
  
  const getInputNode = () => {
    switch (inputType) {
      case "select":
        return (
          <Select
            defaultValue={originalValue}
            options={selectOptions}
            allowClear
          />
        );
      case "select_multiple":
        return (
          <Select
            mode="multiple"
            allowClear
            defaultValue={originalValue}
            options={selectOptions}
          />
        );
      case "select_datalist":
        return (
          <>
            <input
              type="text"
              defaultValue={originalValue}
              allowClear
              list={dataIndex}
              className="ant-input-affix-wrapper css-dev-only-do-not-override-1w4v3hc ant-input"
            />
            <datalist id={dataIndex}>
              {selectOptions.map((option) => (
                <option key={option.value}>{option.value}</option>
              ))}
            </datalist>
          </>
        );
      default:
        return (
          <Input type={inputType} defaultValue={originalValue} allowClear />
        );
    }
  };

  const handleUpdate = async (fd) => {
    console.log(fd);
    await updateApi(record.id, fd);
    alert("Updated Item");
    setEditingKey("");
    if (!shouldNotReload) {
      // window.location.reload(); // Refresh the page
    }
    for (var k in fd) {
      setChildrenState(fd[k]);
    }
  };

  useEffect(()=>{
    if (children) {
      setChildrenState(children)
    }
  },[children])
  return (
    <div {...restProps}>
      {editing ? (
        <Form onFinish={handleUpdate}>
          <Form.Item
            name={dataIndex}
            style={{ margin: 0, width: "100%" }}
            initialValue={originalValue}
          >
            {getInputNode()}
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit">
              <SaveOutlined />
            </Button>
          </Form.Item>
        </Form>
      ) : (
        childrenState
      )}
    </div>
  );
};

export default EditableCell;
