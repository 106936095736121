
import axios from "../../../../api/axios";

export const getInstructorsApi = async () => {
  try {
    const response = await axios.get("/admin/instructors");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addInstructorApi = async (instructorData) => {
  try {
    const response = await axios.post("/admin/instructors", instructorData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateInstructorApi = async (id, instructorData) => {
  try {
    const response = await axios.put(`/admin/instructors/${id}`, instructorData);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const deleteInstructorApi = async (id) => {
  try {
    const response = await axios.delete(`/admin/instructors/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getAllSubjectsApi = async () => {
  try {
    const response = await axios.get("/admin/subjects");
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const getInstructorRolesApi = async () => {
  try {
    const response = await axios.get("/admin/instructor-roles");
    return response.data;
  } catch (error) {
    throw error;
  }
};
