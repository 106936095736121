import React, { useState } from "react";
import { Button, Space, Table } from "antd";
import { EditOutlined, EyeFilled } from "@ant-design/icons";
import EditableCell from "../../../../components/EditableCell";
import ExamSubjectMarksModal from "./ExamSubjectMarksModal";
import { getExamSubjectMarksApi } from "./api";
const { Column } = Table;

const ExamTable = ({ examList }) => {
    const [marksModalIsOpen, setMarksModalIsOpen] = useState(false)
    const [examId, setExamId] = useState(null)

    const toggleMarksModal = (status) => {
        setMarksModalIsOpen(status || !marksModalIsOpen);
    };
    const handleShowExamMarks = async (examId) => {
        setExamId(examId)
        toggleMarksModal(true)
    }
    return (
        <>
            <Table dataSource={examList} bordered={true}>
                <Column
                    title="Name"
                    dataIndex={"name"}
                    key={"name"}
                />
                <Column
                    title="Date of Examination"
                    dataIndex={"date_of_examination"}
                    key={"date_of_examination"}
                />
                <Column
                    title="Remarks"
                    dataIndex={"remarks"}
                    key={"remarks"}
                />
                <Column
                    title="Status"
                    dataIndex={"status"}
                    key={"status"}
                />
                <Column
                    title="Result Published At"
                    dataIndex={"result_published_at"}
                    key={"result_published_at"}
                />
                <Column
                    title="Action"
                    key="action"
                    render={(_, record) => (
                        <Space size="middle">
                            {['results_published'].includes(record.status) && <Button onClick={() => handleShowExamMarks(record.id)}>
                                <EyeFilled />
                            </Button>}
                        </Space>
                    )}
                />
            </Table >
            <ExamSubjectMarksModal examId={examId} marksModalIsOpen={marksModalIsOpen} toggleMarksModal={toggleMarksModal} />
        </>
    );
};

export default ExamTable;

