import axios from "../../../../api/axios";
export const getExamsApi = async () => {
  try {
    const response = await axios.get("/admin/exams");
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getSubjectApi = async (subjectId)=>{
  try {
    const response = await axios.get(`/admin/subjects/${subjectId}`);
    return response.data;
  } catch (error) {
    throw error;
  }

}
export const createExamApi = async (examData) => {
  try {
    const response = await axios.post("/admin/exams", examData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateExamApi = async (id, examData) => {
  try {
    const response = await axios.put(`/admin/exams/${id}`, examData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteExamApi = async (id) => {
  try {
    const response = await axios.delete(`/admin/exams/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const associateSubjectsToExamApi = async (examSubjectData) => {
  try {
    const response = await axios.post("/admin/exams/subjects", examSubjectData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const codeAnswerSheetsApi = async (examSubjectId) => {
  try {
    const response = await axios.post(`/admin/exams/subject/${examSubjectId}/code`);
    return response.data;
  } catch (error) {
    throw error;
  }
}
export const getExamSubjectMarksApi = async (examSubjectId) => {
  try {
    const response = await axios.get(`/admin/exams/subject/${examSubjectId}/marks`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateExamSubjectMarksApi = async (markId, markData) => {
  if (markData["internalMark"]) markData.internalMark = parseFloat(markData.internalMark)
  if (markData["examMark"]) markData.examMark = parseFloat(markData.examMark)
  markData.instructorId = null;
  try {
    const response = await axios.post(`/admin/marks/${markId}`, { fields: markData });
    return response.data;
  } catch (error) {
    throw error;
  }
}
